import { Avatar } from "@mui/material";
import { IPFS_GATEWAY_URL } from "../abis/constants";

export default function TokenLogo({ logo, size, style }: { logo: string; size?: string | number; style?: any }) {
    return (
        <Avatar
            src={`${IPFS_GATEWAY_URL}/${logo}`}
            sx={{
                width: size ?? '120px', 
                height: size ?? '120px',
                borderRadius: "12px",
                objectFit: 'cover',
                ...style,
            }}
        />
    );
}
