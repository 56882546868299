import { Box, Typography, styled } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import refreshIcon from '../../assets/images/refresh.svg';
import TokenLogo from "../tokenLogo";
import { priceFormatter } from "../tvchart/chart";
import { Link } from "react-router-dom";
import React from "react";
import axios from "axios";
import { SERVER_URL } from "../../abis/constants";

const TokenCard = styled(Box)`
    position: relative;
    background: #0006;
    border-radius: 16px;
    background: linear-gradient(#110B28, #110B28) padding-box,
        linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    border: 1px solid transparent;
    padding: 24px;
    display: flex;
    flex-direction: column;
    &:hover {
        box-shadow: 0px 0px 10px 5px #FDFFB370;
    }
`

export default function BalanceCard({ item, style }: { item: any, style?: any }) {
    const [data, setData] = React.useState<any>(item)

    async function handleRefresh() {
        axios.get(`${SERVER_URL}/users/balance/${data.id}`).then(({data}) => setData(data)).catch(() => {})
    }
    return (
        <TokenCard style={style}>
            <Box display="flex" gap="10px" alignItems="flex-start" flexDirection="column">
                <TokenLogo logo={data.token?.tokenImage} style={{ borderRadius: '30px', width: '50px', height: '50px' }}/>
                <div style={{ fontFamily: "Inter", display: "flex", flexDirection: "column" }}>
                    <Box display="flex" gap="4px" alignItems="baseline">
                        <Typography color="white" fontSize="1.6em">{priceFormatter(data.tokenAmount)}</Typography>
                        <Typography color="#D9D9D9" fontSize="1.4em">{data.token?.tokenSymbol}</Typography>
                    </Box>
                    <Box display="flex" gap="4px">
                        <Typography color="#D9D9D9" fontSize={{ sm: 14, xs: 12 }}>{priceFormatter(data.tokenAmount * data.token?.price)}</Typography>
                        <Typography color="#9E9E9E" fontSize={{ sm: 14, xs: 12 }}>PLS</Typography>
                    </Box>
                </div>
            </Box>
            {/* <p style={{ color: '#9E9E9E' }}>{item.token?.tokenDescription}</p> */}
            <Box display="flex" alignItems={"center"} justifyContent="space-between" mt={2}>
                <Link to="" onClick={handleRefresh} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <img src={refreshIcon} />
                    <Typography color="#9E9E9E" fontSize={12} ml="6px">Refresh</Typography>
                </Link>
                <Link to={`/${data.token?.network}/${data.tokenAddress}`} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <Typography color="#E7E3D8" fontSize={12}>View coin</Typography>
                    <KeyboardArrowRightIcon sx={{ color: "#E7E3D8" }} />
                </Link>
            </Box>
        </TokenCard>
    )
}