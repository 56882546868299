const isOnline = true
export const isDevEnv = process.env.REACT_APP_ENV === "development"

export const DEV_SERVER_URL = `http://localhost:5000`
export const DEV_HARDHAT_RPC_URL = "http://127.0.0.1:8545/"
export const DEV_PULSECHAIN_RPC_URL = "https://rpc-pulsechain.g4mm4.io"
export const DEV_CONTRACT_ADDRESS = "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853"

export const SERVER_URL = isDevEnv ? DEV_SERVER_URL : `https://apipumpfork.9inch.io`

export const TOTAL_SUPPLY = 1_000_000_000
export const TARGET_MARKET_CAP = 69_000
export const TARGET_LP_AMOUNT = 12_000

export const PULSECHAIN_CONTRACT_ADDRESS = !isOnline ? DEV_CONTRACT_ADDRESS : "0xb3233bB2089251973D126F733A9ad6216f29caa7"
export const PULSECHAIN_CHAIN_ID = !isOnline ? 31337 : 369
export const PULSECHAIN_RPC_URL = isOnline ? isDevEnv ? DEV_PULSECHAIN_RPC_URL : "https://rpc-pulsechain.g4mm4.io" : DEV_HARDHAT_RPC_URL
export const PULSECHAIN_SCAN_URL = "https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#"

export const IPFS_UPLOAD_URL = "https://api.pinata.cloud/pinning/pinFileToIPFS"
//export const IPFS_GATEWAY_URL = "https://coffee-magnetic-rattlesnake-502.mypinata.cloud/ipfs"
export const IPFS_GATEWAY_URL = isDevEnv ? `${DEV_SERVER_URL}/uploads/` : "https://coinhublogos.9inch.io/"

export const COINHUB_TELEGRAM_URL = "https://t.me/coinhub_9inch"
export const COINHUB_TWITTER_URL = "https://x.com/coindotquest"
export const COINHUB_WEBSITE_URL = "https://coinhub.9inch.io"