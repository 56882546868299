import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Pagination, PaginationItem, Select, SelectChangeEvent, Slide, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography, styled } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import TokenCard from "../../components/cards/token";
import Toggle from "../../components/toggle";
import ShakeBox from "../../components/cards/shake";
import { Creator, User, UserAvatar, UserName } from "../../components/cards/user";
import { IPFS_GATEWAY_URL, SERVER_URL } from "../../abis/constants";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { socket } from "../../utils/socket";

import repliesIcon from '../../assets/images/replies.png';
import marketcapIcon from '../../assets/images/marketcap.png';
import ArrowIcon from '../../assets/images/arrow-right.svg';
import userAddIcon from '../../assets/images/user-add.svg';

import { BannerBox, Banner, SearchToken, LetsGo, OrderGroup, CardGrid, OrderBoxRoot, GradientBorder } from "./DashboardStyles";
import { priceFormatter } from "../../components/tvchart/chart";
import PageBox from "../../components/layout/pageBox";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import { BootstrapInput } from "../createCurvs";

const HiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clipPath: inset(50%);
    height: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 100%;
`

const Option = styled('button')<{ checked: boolean }>`
    background: none;
    color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
    padding: 4px;
    ${({checked}) => (
        checked ? `
            background: #E12D85;
        ` : ''
    )}
`

const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

function OrderBox(props: any) {
    const [open, setOpen] = useState(false)
    return <OrderBoxRoot onClick={() => setOpen(!open)}>
        <Box component="label" display="flex" gap="4px" alignItems="center">
            <Typography component="span" color="#B0B5B3" fontSize="1em">{props.label}: </Typography>
            <Typography component="span" color="white" fontSize="1em">{props.values[props.value]}</Typography>
            <ArrowDownIcon sx={{ color: "white" }}/>
        </Box>
        {
            open &&
            <>
                <div className="overlay" onClick={() => setOpen(false)} />
                <GradientBorder className="menu">
                    <ul>
                        {
                            Object.entries(props.values)?.map(([key, label]: [string, any]) =>
                                <li className={ key===props.value ? 'active' : '' } onClick={() => {
                                    props.onChange?.(key)
                                    setOpen(false)
                                }}>
                                    {label}
                                    { 
                                        key===props.value && <CheckIcon sx={{ height: 18 }} />
                                    }
                                </li>
                            )
                        }
                    </ul>
                </GradientBorder>
            </>
        }
    </OrderBoxRoot>
}

function DashboardPage() {
    const { address, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const { code } = useParams()
    const navigate = useNavigate()

    let checkedStatus = localStorage.getItem('checked');
    const [curvsList, setCurvsList] = React.useState([]);
    const [curvsCount, setCurvsCount] = React.useState(0);
    // const [open, setOpen] = React.useState(checkedStatus !== null && JSON.parse(checkedStatus) === true ? false : true);
    const [orderType, setOrderType] = React.useState('bump');
    const [orderFlag, setOrderFlag] = React.useState('DESC');
    const [searchWord, setSearchWord] = React.useState('');
    const [mode, setMode] = React.useState('terminal')
    const [network, setNetwork] = React.useState('All');
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(10);
    const [king, setKing] = React.useState<any>(null);
    const [followings, setFollowings] = React.useState<any[]>([]);
    const [now, setNow] = useState(0)
    const [modal, setModal] = useState<string>()
    const [includeNSFW, setIncludeNSFW] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [telegramLink, setTelegramLink] = React.useState('');
    const [twitterLink, setTwitterLink] = React.useState('');
    const [webLink, setWebLink] = React.useState('');
    const [banner, setBanner] = React.useState<any>()

    const fileBannerRef = React.useRef<any>()

    const searchTokenInputProps = useMemo(() => ({
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ),
        endAdornment: (
            <InputAdornment style={{ cursor: "pointer", visibility: searchWord.length > 0 ? 'visible' : 'hidden' }} position="end" onClick={() => {
                setSearchWord('')
            }}>
                <CloseIcon sx={{ height: 18 }} />
            </InputAdornment>
        )
    }), [searchWord])

    const [latestTradeId, setLatestTradeId] = React.useState(0)
    const [latestTokenId, setLatestTokenId] = React.useState("")

    useEffect(() => {
        getMyTokens()
        const intervalId = setInterval(() => setNow(Date.now()), 1000)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        getMyTokens().then(() => { });
        getFollowings().then(() => { });
    }, [now])

    useEffect(() => {
        if(code)
            localStorage.setItem('referral', code)
        // else
        //     localStorage.removeItem('referral')
    }, [code])

    const getMyTokens = useCallback(async () => {
        const params = {
            orderType,
            orderFlag,
            searchWord,
            network,
            pageNumber,
            includeNSFW
        }
        try {
            await axios.get(`${SERVER_URL}/tokens`, {
                params
            }).then(function (response) {
                if (latestTokenId && latestTokenId !== "") {
                    const tokenIndex = response.data.tokenList.findIndex((token: any) => token.tokenAddress === latestTokenId);
                    if (tokenIndex !== -1) {
                        const token = response.data.tokenList[tokenIndex];
                        response.data.tokenList.splice(tokenIndex, 1);
                        response.data.tokenList.unshift(token);
                    }
                }
                setCurvsCount(response.data.tokenCount);
                setCurvsList(response.data.tokenList);
                setTotalPage(response.data.totalPages);
                if(pageNumber > response.data.totalPages)
                    setPageNumber(1)
                setKing(response.data.king?.token);
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {

        }
    }, [orderType, orderFlag, searchWord, network, pageNumber, includeNSFW])

    const getFollowings = useCallback(async () => {
        try {
            await axios.get(`${SERVER_URL}/users/followings/${address}`)
                .then(function (response) {
                    setFollowings(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                })
        } catch (error) {

        }
    }, [address])

    const handleChange = (event: any, value: any) => {
        setPageNumber(value);
        // This logs the current page number to the console
    };

    // React.useEffect(() => {
    //     getMyTokens();
    // }, []);

    // React.useEffect(() => {
    //     getMyTokens();
    // }, [pageNumber, orderType, orderFlag, searchWord, network]);

    // const handleClose = () => {
    //     localStorage.setItem('checked', JSON.stringify(true));
    //     setOpen(false);
    // };

    const uploadLogo = async (img: any) => {
        let formData = new FormData(); // instantiate it
        formData.set('image', img);
        const r = axios.post(`${SERVER_URL}/tokens/upload`, formData, {
            headers: {
                "api-key": "hola",
                'content-type': 'multipart/form-data' // do not forget this 
            }
        })
        return r;
    }

    const handleBanner = (e: any) => {
        console.log('banner')
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setBanner(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const bannerOnClick = () => {
        navigate(`/${king.network}/${king.tokenAddress}`);
    }

    const handleUpdate = useCallback(async () => {
        setUpdating(true);
        try {
            if (!isConnected) {
                toast.error("Please connect wallet");
                return;
            }
            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await ethersProvider.getSigner()
            const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
            const signature = await signer.signMessage(msg)
            let bannerLink;
            if (fileBannerRef.current && fileBannerRef.current.files && fileBannerRef.current.files.length > 0) {
                const bannerLogoResult = await uploadLogo(fileBannerRef.current.files[0])
                bannerLink = bannerLogoResult.data.file.filename
            }
            const metadata = {
                msg,
                signature,
                tokenAddress: modal,
                tokenBanner: bannerLink,
                telegramLink: telegramLink,
                twitterLink: twitterLink,
                webLink: webLink
            }

            const { data: { success } } = await axios.post(`${SERVER_URL}/tokens`, metadata)
            if (success) {
                toast.success("Token deployed successfully")
            }
            setUpdating(false)
            setModal(undefined)
        } catch(ex) {
            setUpdating(false)
        }
    }, [isConnected, modal, telegramLink, twitterLink, webLink, banner, walletProvider])

    // useEffect(() => {
    //     if (searchWord && searchWord.length > 0) {
    //         setSearchTokenInputProps({
    //             startAdornment: (
    //                 <InputAdornment position="start">
    //                     <SearchIcon />
    //                 </InputAdornment>
    //             ),
    //             endAdornment: (
    //                 <InputAdornment style={{ cursor: "pointer" }} position="end" onClick={() => {
    //                     setSearchWord('')
    //                 }}>
    //                     <CloseIcon />
    //                 </InputAdornment>
    //             )
    //         })
    //     } else {
    //         setSearchTokenInputProps({
    //             startAdornment: (
    //                 <InputAdornment position="start">
    //                     <SearchIcon />
    //                 </InputAdornment>
    //             ),
    //         })
    //     }

    // }, [searchWord])

    const getLatestTrade = useCallback(async () => {
        try {
            await axios.get(SERVER_URL, {
                params: {
                    latestTradeId,
                    latestTokenId,
                }
            }).then(function (response: any) {
                const { trades, tokens } = response.data
                if (trades.length) {
                    setLatestTokenId(trades[0].tokenAddress)
                }
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {

        }
    }, [latestTradeId, latestTokenId])

    async function handleFollow() {
        try {
            const provider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await provider.getSigner()
            const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
            const signature = await signer.signMessage(msg)
            axios.post(`${SERVER_URL}/users/follow/${address}`, {
                signature, msg
            }).then(({data}) => {
                
            }).catch(err => {})
        } catch(err) {}
    }

    React.useEffect(() => {
        socket.on("message", (data: any) => {
            getLatestTrade();
        })

        return () => {
            socket.off("message");
        }
    }, []);

    React.useEffect(() => {
        if (modal) {
            const token: any = curvsList.find((t: any) => t.tokenAddress===modal)
            if (token) {
                setTelegramLink(token.telegramLink)
                setTwitterLink(token.twitterLink)
                setWebLink(token.webLink)
            }
        }
    }, [modal])

    return (
        <PageBox>
            <BannerBox>
                <div className="banner-bg" />
                <div className="banner-actor" />
                {/* <div className="banner-overlay"/> */}
                <Banner onClick={bannerOnClick}>
                    <Box sx={{ flex: 1 }} display="flex" flexDirection="column" justifyContent="flex-end">
                        <label>
                            <Typography color="white" fontSize={{ sm: "88px", xs: "60px" }} fontFamily="Riffic Free" pr="0.8em">King</Typography>
                            <Typography color="white" fontSize={{ sm: "48px", xs: "32px" }} fontFamily="Riffic Free" mt="-0.7em">of the hill</Typography>
                        </label>
                    </Box>
                    {
                        !!king &&
                        <>
                            <img src={`${IPFS_GATEWAY_URL}/${king.tokenImage}`} className="token-logo" />
                            <Box sx={{ flex: 1, fontFamily: "Inter", display: "flex", flexDirection: "column", gap: "1em", alignItems: { md: "flex-start", xs: "center" } }}>
                                <Typography color="white" fontSize={{ sm: "32px", xs: "24px" }} fontFamily="Riffic Free">{king.tokenName}</Typography>
                                <Box display="flex" mb={2} mt={-2}>
                                    <Typography color="#9E9E9E" fontSize={14}>Ticker:</Typography>
                                    <Typography color="#D9D9D9" fontSize={14}>{king.tokenSymbol}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: { md: "column", sm: "row", xs: "column" }, gap: "0.6rem" }} alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}>
                                    <Box display="flex" gap="0.2rem" alignItems="center">
                                        <Creator token={king} mr="0.2rem" />
                                        <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                                        <Typography color="#9E9E9E" fontSize={14}>creator</Typography>
                                    </Box>
                                    <Box display="flex" gap="0.2rem" alignItems="center">
                                        <Avatar sx={{ width: 24, height: 24, mr: '0.2rem' }} src={repliesIcon} />
                                        <Typography color="#D9D9D9" fontSize={14}>{king.replies}</Typography>
                                        <Typography color="#D9D9D9" fontSize={14}>replies</Typography>
                                    </Box>
                                    <Box display="flex" gap="0.2rem" alignItems="center">
                                        <Avatar sx={{ width: 24, height: 24, mr: '0.2rem' }} src={marketcapIcon} />
                                        <Typography color="#D9D9D9" fontSize={14}>${priceFormatter(king.marketcap, 2)}</Typography>
                                        <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                                        <Typography color="#9E9E9E" fontSize={14}>Market cap</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                </Banner>
                <Box display="flex" mt={{ md: 8, sm: -4, xs: 4 }} justifyContent="center" flexDirection={{ md: "row", xs: "column" }} alignItems="stretch" gap="2em">
                    <Box component={Link} to="/create" style={{ cursor: "pointer", textDecoration: 'none' }} display="flex" gap="0.5rem" alignItems="center" flexDirection={{ sm: "row", xs: "column" }} justifyContent="center">
                        <Typography color="white" fontFamily="Riffic Free" fontSize="24px">Launch a new memecoin!</Typography>
                        <Box display="flex" alignItems={"center"}>
                            <LetsGo>
                                <Typography color="#E12D85" fontFamily="Riffic Free" fontSize="24px">Lets go!</Typography>
                            </LetsGo>
                            <Avatar sx={{ width: 24, height: 24 }} src={ArrowIcon} />
                        </Box>
                    </Box>
                    {/* <Box display={{ md: 'block', sm: 'none', xs: 'none' }} flex={1} /> */}
                </Box>
            </BannerBox>
            <Box display="flex" justifyContent="space-between" flexDirection={{ sm: "row", xs: "column" }} alignItems="center" gap="1rem" flexWrap="wrap" mb={1}>
                <Toggle>
                    <div className={mode === "terminal" ? "active" : ""} onClick={() => setMode("terminal")}>Terminal({curvsCount})</div>
                    {
                        !!address &&
                        <div className={mode === "following" ? "active" : ""} onClick={() => setMode("following")}>Following({followings.length})</div>
                    }
                </Toggle>
                {
                    mode === "terminal" &&
                    <Box display="flex" justifyContent="space-between" alignSelf="stretch" flexDirection={{ sm: "row", xs: "column" }} alignItems="center" gap="1em">
                        <SearchToken placeholder="Search for token" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} InputProps={searchTokenInputProps} />
                        <OrderBox ml="auto" label="Sort" values={{
                            bump: 'Bump order', 
                            marketcap: 'Market cap', 
                            creationTime: 'Creation time', 
                            lastreply: 'Last reply', 
                            replies: 'Replies' 
                        }} value={orderType} onChange={setOrderType} />
                        {/* <OrderBox label="Order" values={{ ASC: 'Asc', DESC: 'Desc' }} value={orderFlag} onChange={setOrderFlag} /> */}
                    </Box>
                }
            </Box>
            <Box display="flex" justifyContent={{ sm: "flex-end", xs: "center" }} alignItems="center" gap="4px" mb={2}>
                <Typography color="white">Include nsfw: </Typography>
                <Option checked={includeNSFW} onClick={() => setIncludeNSFW(true)}>On</Option>
                <Option checked={!includeNSFW} onClick={() => setIncludeNSFW(false)}>Off</Option>
            </Box>
            {
                !!address && mode === "following" &&
                <>
                    <Typography color="#B0B5B3" fontSize={14} textAlign={{ xs: "center" }}>
                        Follow some of your friends to start curating your feed
                    </Typography>
                    <Typography color="white" fontSize={20} lineHeight={4} textAlign={{ xs: "center" }}>
                        People you may know
                    </Typography>
                    <Box mt={2} mb={4} display="grid" gap="24px" gridTemplateColumns={{ md: 'repeat(4, 1fr)', sm: 'repeat(3, 1fr)', xs: 'repeat(2, 1fr)' }}>
                        {
                            followings.map((item: any, index: number) => (
                                <Box display="flex" flexDirection="column" alignItems={{ xs: "center", md: "flex-start" }} gap="8px" key={`followee-${index}`}>
                                    <Box display="flex" alignItems="center" flexDirection={{ md: 'row', xs: 'column' }} gap="8px" key={`followee-${index}`}>
                                        <UserAvatar user={item.user} address={item.followeeId} size={40} mr={0}/>
                                        <Box display="flex" justifyContent="space-between" flexDirection="column" alignItems={{ xs: "center", md: "flex-start" }}>
                                            <UserName user={item.user} address={item.followeeId} color="#E7E3D8" />
                                            <Typography color="#9E9E9E" fontSize={12} whiteSpace="nowrap">{item.followee?.followers ?? 0} followers</Typography>
                                        </Box>
                                    </Box>
                                    <Button color="primary" sx={{ borderRadius: '16px', textTransform: 'none', p: '10px 16px', ml: { md:'48px', sm: 0, xs: 0 }, width: 'fit-content' }} onClick={handleFollow}>
                                        <img src={userAddIcon} style={{ filter: 'brightness(10)' }} />
                                        <Typography color="white" fontSize={14} ml={1}>Follow</Typography>
                                    </Button>
                                </Box>
                            ))
                        }
                    </Box>
                </>
            }
            {
                mode === "terminal" &&
                <Box display="flex" justifyContent="center">
                    {
                        curvsList.length > 0 ? 
                        <CardGrid mb={4}>
                            {
                                curvsList.map((item: any, index: number) => (
                                    <ShakeBox animate={index === 0} key={item.tokenAddress}>
                                        <TokenCard token={item} onUpdate={item.creatorAddress===address ? (tokenAddress: string) => setModal(tokenAddress) : undefined} />
                                    </ShakeBox>
                                ))
                            }
                        </CardGrid> :
                        <Typography textAlign='center' color="#aaa" my={4}>No token found</Typography>
                    }
                </Box>
            }
            {
                mode === "terminal" && totalPage > 0 &&
                <Grid2 sx={{ display: 'flex' }}>
                    <Stack spacing={2} mx='auto'>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={totalPage}
                            page={pageNumber}  // current page
                            onChange={handleChange}  // handle page change
                            renderItem={(data) => (
                                <PaginationItem
                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...data}
                                />
                            )}
                        />
                    </Stack>
                </Grid2>
            }
            <Dialog
                open={!!modal}
                fullWidth
                onClose={() => setModal(undefined)}
            >
                <DialogTitle>
                    Update token information
                    <IconButton onClick={() => setModal(undefined)}>
                        <CloseIcon sx={{ color: "#9E9E9E" }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl variant="standard">
                        <InputLabel shrink>
                            Telegram Link
                        </InputLabel>
                        <BootstrapInput fullWidth placeholder="Telegram Link" value={telegramLink} onChange={(e) => setTelegramLink(e.target.value)} />
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink>
                            Twitter Link
                        </InputLabel>
                        <BootstrapInput fullWidth placeholder="Twitter Link" value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} />
                    </FormControl>
                    <FormControl variant="standard">
                        <InputLabel shrink>
                            Website Link
                        </InputLabel>
                        <BootstrapInput fullWidth placeholder="Website Link" value={webLink} onChange={(e) => setWebLink(e.target.value)} />
                    </FormControl>
                    <IconButton sx={{ borderRadius: '16px', textTransform: 'none', background: '#FFFFFF0F', p: '12px 20px', fontSize: 16 }} component="label">
                        Select banner image
                        <HiddenInput ref={fileBannerRef} type="file" onChange={handleBanner} />
                    </IconButton>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModal(undefined)} color="secondary" fullWidth disabled={updating}>Close</Button>
                    <Button onClick={handleUpdate} fullWidth disabled={updating} endIcon={updating ? <CircularProgress color="inherit" size={14} /> : undefined}>
                        {updating ? "Updating" : "Update"}
                    </Button>
                </DialogActions>
            </Dialog>
        </PageBox>
    );
}

export default DashboardPage;
