import { Avatar, Badge, Box, Button, Typography, styled } from "@mui/material";
import PageBox from "../components/layout/pageBox";
import { Link, useParams } from "react-router-dom";
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { useEffect, useState } from "react";
import { priceFormatter } from "../components/tvchart/chart";
import { User } from "../components/cards/user";
import axios from "axios";
import { SERVER_URL } from "../abis/constants";

const RANKING_COLORS = ['#FFBD2B', '#B0B5B3', '#D68E4D']

const Row = styled(Box)`
    background: #00000030;
    border-radius: 16px;
    border: 1px solid transparent;
    display: grid;
    grid-template-columns: 4fr 5fr 5em;
    align-items: center;
    padding: 0.2em 2em;
    &.ranked {
        background: #2b263f;
    }
    &:hover {
        background: linear-gradient(#171228, #171228) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    }
    &.ranked:hover {
        background: linear-gradient(#2b263f, #2b263f) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    }
`

const Header = styled(Box)`
    border-radius: 16px;
    background: linear-gradient(180deg, #493496 0%, #211744 100%) padding-box,
        linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    border: 1px solid transparent;
    font-family: Riffic Free;
    display: grid;
    grid-template-columns: 4fr 5fr 5em;
    align-items: center;
    padding: 0.8em 2em;
`

const Ranking = styled('span')<{order: number}>`
    color: white;
    font-family: Riffic Free;
    width: 2.5em;
    height: 2.5em;
    border-radius: 2em;
    text-align: center;
    line-height: 2.5em;
    border: 1px solid transparent;
    background: ${
        ({ order }) => 
        RANKING_COLORS[order] ? `
            linear-gradient(180deg, ${RANKING_COLORS[order]} 0%, ${RANKING_COLORS[order]} 100%) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box
        ` : '#51419E'
    };
`

export default function LeaderboardPage() {
    const [users, setUsers] = useState<any[]>([])

    useEffect(() => {
        axios.get(`${SERVER_URL}/users/ranking`).then(({data: users}) => {
            setUsers(users)
        })
    }, [])

    return (
        <PageBox>
            <Box display="flex" justifyContent="flex-end">
                <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon sx={{ color: 'white', height: 24 }} />
                    <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                </Link>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px" py="1.5em">
                <Header>
                    <Typography color="white" fontFamily="inherit">Ranking</Typography>
                    <Typography color="white" fontFamily="inherit">User</Typography>
                    <Typography color="white" fontFamily="inherit">Points</Typography>
                </Header>
                {
                    users.length > 0 && users.map((user: any, i: number) => (
                        <Row key={`rank-${i}`} className={ i<3 ? 'ranked' : ''}>
                            <Ranking order={i}>{i+1}</Ranking>
                            <Box display="flex" alignItems="center">
                                <User user={user} color="#9E9E9E" size={18} />
                            </Box>
                            <Typography color="white" textAlign="right">{priceFormatter(user.ranking, 1)}</Typography>
                        </Row>
                    ))
                }
            </Box>
        </PageBox>
    )
}