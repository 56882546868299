import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import "animate.css"

export default function ShakeBox({ animate, ...props}: any) {
    const ref = useRef<any>()
    useEffect(() => {
        if(ref.current && animate) {
            ref.current.classList.remove("animate__shakeX")
            setTimeout(() => ref.current.classList.add("animate__shakeX"), 1)
        }
    }, [animate])
    return <Box className="animate__animated" ref={ref} {...props}/>
}