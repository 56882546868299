import { Avatar, Badge, Box, Button, IconButton, Tooltip, Typography, setRef, styled } from "@mui/material";
import PageBox from "../components/layout/pageBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import CopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/OpenInNewOutlined';
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import copy from 'copy-to-clipboard';
import axios from "axios";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import userAddIcon from "../assets/images/user-add.svg";
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import usersIcon from "../assets/images/users.svg";
import Toggle from "../components/toggle";
import BalanceCard from "../components/cards/balance";
import Chat from "../components/cards/chat";
import TokenCard from "../components/cards/token";
import { useUserInfo } from "../hooks/user";
import { User, UserAvatar, UserName } from "../components/cards/user";
import { PULSECHAIN_SCAN_URL, SERVER_URL } from "../abis/constants";
import { GradientBorder } from "./Dashboard/DashboardStyles";
import { priceFormatter } from "../components/tvchart/chart";

const StyledBadge = styled(Badge)`
    & .MuiBadge-badge {
        transform: translate(100%, -50%);
        color: white;
        font-size: small;
    }
`

const CardGrid1 = styled(Box)`
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    ${props => props.theme.breakpoints.up(1400)} {
        grid-template-columns: repeat(3, 1fr);
    }
    ${props => props.theme.breakpoints.down(800)} {
        grid-template-columns: 1fr;
        gap: 16px;
    }
`

const CardGrid2 = styled(Box)`
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    ${props => props.theme.breakpoints.up(1600)} {
        grid-template-columns: repeat(3, 1fr);
    }
    ${props => props.theme.breakpoints.down(800)} {
        grid-template-columns: 1fr;
        gap: 16px;
    }
`

const ReferralButton = styled(Box)`
    display: flex;
    gap: 6px;
    padding: 14px 32px;
    border-radius: 16px;
    background: linear-gradient(180deg, #493496 0%, #211744 100%) padding-box,
        linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    border: 1px solid transparent;
`

export const ComboBoxRoot = styled(Box)`
  & > label {
    padding: 16px;
    height: 100%;
    background: white;
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      background: #FFFD;
    }
  }
  position: relative;
  & .overlay {
    position: fixed;
    top:0; left:0; right:0; bottom:0;
    z-index: 3;
  }
  & .menu {
    position: absolute;
    top:calc(100% + 6px); right:0;
    border-radius: 10px;
    min-width: calc(100% - 2px);
    box-sizing: border-box;
    z-index: 3;
  }
  & ul {
    background: #110B28;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 9px;
    color: #B0B5B3;
    font-family: Inter;
    font-size: 14px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0.8em 1em;
      &:hover {
        color: #E12D85;
      }
      &.active {
        color: #E12D85;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    align-self: stretch;
    & > label {
      padding: 12px 16px;
    }
  }
`

function ComboBox({ values, value, onChange, ...props }: any) {
    const [open, setOpen] = useState(false)
    return <ComboBoxRoot onClick={() => setOpen(!open)} {...props}>
        <Box component="label" display="flex" gap="4px" alignItems="center" justifyContent="space-between">
            <Typography component="span" color="#433DFF" fontSize="1em" flex={1} textAlign="center">{values[value]}</Typography>
            <ArrowDownIcon sx={{ color: "#433DFF" }}/>
        </Box>
        {
            open &&
            <>
                <div className="overlay" onClick={() => setOpen(false)} />
                <GradientBorder className="menu">
                    <ul>
                        {
                            Object.entries(values)?.filter(([key, label]: [string, any]) => !!label)?.map(([key, label]: [string, any]) =>
                                <li className={ key===value ? 'active' : '' } onClick={() => {
                                    onChange?.(key)
                                    setOpen(false)
                                }}>
                                    {label}
                                    { 
                                        key===value && <CheckIcon sx={{ height: 18 }} />
                                    }
                                </li>
                            )
                        }
                    </ul>
                </GradientBorder>
            </>
        }
    </ComboBoxRoot>
}

export default function ProfilePage() {
    const { address: walletAddress } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const { address, tab: section } = useParams()
    const { userInfo: myInfo } = useUserInfo()
    const [ userInfo, setUserInfo ] = useState<any>()
    const [ copied, setCopied ] = useState(false)

    const navigate = useNavigate()

    const [ tab, setTab ] = useState(section ?? "held")

    const [ coinsHeld, setCoinsHeld ] = useState<any[]>([])
    const [ chatList, setChatList ] = useState<any[]>([])
    const [ coinsCreated, setCoinsCreated ] = useState<any[]>([])
    const [ followers, setFollowers ] = useState<any[]>([])
    const [ followees, setFollowees ] = useState<any[]>([])
    const [ refferals, setReferrals ] = useState(0)
    const [ points, setPoints ] = useState<any>({})

    const userAddress = useMemo(() => userInfo?.address ?? (address==="me" ? walletAddress : address), [userInfo, address, walletAddress])

    const referralLink = useMemo(() => `https://coin.quest/ref/${userAddress?.slice(8, 16)}`, [userAddress])

    async function handleFollow() {
        try {
            const provider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await provider.getSigner()
            const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
            const signature = await signer.signMessage(msg)
            axios.post(`${SERVER_URL}/users/follow/${address}`, {
                signature, msg
            }).then(({data}) => {
                if(data.success) {
                    fetchProfileData()
                } else {

                }
            }).catch(err => {})
        } catch(err) {}
    }

    async function handleUnfollow(id: string) {
        try {
            const provider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await provider.getSigner()
            const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
            const signature = await signer.signMessage(msg)
            axios.post(`${SERVER_URL}/users/unfollow/${id}`, {
                signature, msg
            }).then(({data}) => {
                if(data.success) {
                    fetchProfileData()
                }
            }).catch(err => {})
        } catch(err) {}
    }

    function fetchProfileData() {
        axios.get(`${SERVER_URL}/users/profile/${userAddress}`).then(({ data }) => {
            if(data.helds)
                setCoinsHeld(data.helds)
            if(data.replies)
                setChatList(data.replies)
            if(data.tokens)
                setCoinsCreated(data.tokens)
            if(data.followers)
                setFollowers(data.followers)
            if(data.followees)
                setFollowees(data.followees)
            if(data.referrals) 
                setReferrals(data.referrals)
            if(data.points) 
                setPoints(data.points)
        }).catch(() => {})
    }

    function handleCopy() {
        setCopied(true)
        copy(referralLink)
        setTimeout(() => setCopied(false), 5000)
    }

    useEffect(() => {
        if(address==="me" || address===myInfo?.username || address===walletAddress)
            setUserInfo(myInfo)
        else if(!userInfo || address!==userInfo.address) {
            axios.get(`${SERVER_URL}/users`, {
                params: {
                    userAddress: address
                }
            }).then(({ data }) => {
                setUserInfo(data)
            }).catch(() => {
                setUserInfo(undefined)
            })           
        }
    }, [address, myInfo, walletAddress])

    useEffect(() => {
        if(userAddress) {
            fetchProfileData()
        }
    }, [userAddress, walletAddress])

    useEffect(() => {
        if(section)
            setTab(section)
    }, [section])

    useEffect(() => {
        navigate(`/profile/${address}/${tab}`)
    }, [tab])

    return (
        <PageBox>
            <Box display="flex" gap={{ sm: 4, xs: 2 }} flexDirection={{ sm: 'row', xs: 'column' }} justifyContent='space-between' mb="2em">
                <Box display={{ sm: 'none', xs: 'flex' }} flexDirection="column" alignItems="center">
                    <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                        <ArrowLeftIcon sx={{ color: '#9E9E9E', height: 24 }} />
                        <Typography sx={{ color: '#9E9E9E', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                    </Link>
                    <UserName user={userInfo} address={userAddress} fontSize={32} color="white" fontFamily="Riffic Free" />
                    {
                        !!userInfo?.bio &&
                        <Typography color="#E7E3D8" fontSize={13} mt={1}>
                            { userInfo.bio }
                        </Typography>
                    }
                    <Box mt={4}>
                        <UserAvatar user={userInfo} address={userAddress} size={{ sm: 185, xs: 120 }} mr={0}/>
                    </Box>
                </Box>
                <Box display={{ sm: 'inline', xs: 'none' }}>
                    <UserAvatar user={userInfo} address={userAddress} size={{ sm: 185, xs: 120 }} mr={0}/>
                </Box>
                <Box flex={1}>
                    <Box display={{ sm: 'flex', xs: 'none' }} alignItems='flex-start' justifyContent='space-between'>
                        <UserName user={userInfo} address={userAddress} fontSize={{ sm: 36, xs: 20 }} color="white" fontFamily="Riffic Free" />
                        <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftIcon sx={{ color: 'white', height: 24 }} />
                            <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                        </Link>
                    </Box>
                    <Box display="flex" gap={{ sm: 4, xs: 2 }} mt={1} alignItems="center" flexWrap="wrap" justifyContent={{ sm: "flex-start", xs: "center" }}>
                        {
                            userAddress!==walletAddress && !followers.find((f: any) => f.followerId===walletAddress) &&
                            <Button color="secondary" sx={{ borderRadius: '16px', textTransform: 'none', p: '14px 24px' }} onClick={() => handleFollow()}>
                                <img src={userAddIcon} />
                                <Typography color="#E12D85" fontSize={14} ml={1}>Follow</Typography>
                            </Button>
                        }
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems={{ sm: 'flex-start', xs: 'center' }} gap="4px">
                            <Typography fontSize={14} color="#9E9E9E" noWrap>Followers</Typography>
                            <Typography color="white" fontSize={12} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <img src={usersIcon} />
                                {followers?.length}
                            </Typography>
                        </Box>
                        {/* <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems={{ sm: 'flex-start', xs: 'center' }} gap="4px">
                            <Typography fontSize={14} color="#9E9E9E" noWrap>Likes Received</Typography>
                            <Typography color="white" fontSize={12} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <FavoriteIcon />
                                {userInfo?.likes ?? 0}
                            </Typography>
                        </Box> */}
                        {/* <Box display="flex" flexDirection="column" justifyContent="space-between" gap="4px">
                            <Typography fontSize={14} color="#9E9E9E">Mentions Received</Typography>
                            <Typography color="white" fontSize={12} sx={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                                <ChatIcon />
                                435
                            </Typography>
                        </Box> */}
                        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems={{ sm: 'flex-start', xs: 'center' }} gap="4px">
                            <Typography fontSize={14} color="#9E9E9E" noWrap>Wallet address</Typography>
                            <Link to={`${PULSECHAIN_SCAN_URL}/address/${userAddress}`} target="_blank" style={{ textDecoration: 'none' }}>
                                <Typography color="white" fontSize={12} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <Box component="span" display={{ sm: 'inline', xs: 'none' }}>{userAddress}</Box>
                                    <Box component="span" display={{ sm: 'none', xs: 'inline' }}>
                                        {userAddress?.slice(0, 10)}
                                        ...
                                        {userAddress?.slice(-8)}
                                    </Box>
                                    <LinkIcon />
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                    <Typography color="#E7E3D8" fontSize={13} mt={2} display={{ sm: 'block', xs: 'none' }}>
                        { userInfo?.bio }
                    </Typography>
                </Box>
            </Box>
            <Toggle display={{ sm: "flex", xs: "none" }}>
                <div className={tab === "held" ? "active" : ""} onClick={() => setTab("held")}>
                    <StyledBadge badgeContent={coinsHeld.length} color="info">Coins held</StyledBadge>
                </div>
                <div className={tab === "replies" ? "active" : ""} onClick={() => setTab("replies")}>
                    <StyledBadge badgeContent={chatList.length} color="success">Replies</StyledBadge>
                </div>
                {/* <div className={tab === "notification" ? "active" : ""} onClick={() => setTab("notification")}>
                    <StyledBadge badgeContent={3} color="error">Notification</StyledBadge>
                </div> */}
                <div className={tab === "created" ? "active" : ""} onClick={() => setTab("created")}>
                    <StyledBadge badgeContent={coinsCreated.length} color="success">Coins created</StyledBadge>
                </div>
                <div className={tab === "followers" ? "active" : ""} onClick={() => setTab("followers")}>
                    <StyledBadge badgeContent={followers.length} max={999} color="success">Followers</StyledBadge>
                </div>
                <div className={tab === "followees" ? "active" : ""} onClick={() => setTab("followees")}>
                    <StyledBadge badgeContent={followees.length} max={999} color="success">Following</StyledBadge>
                </div>
                {
                    userAddress === walletAddress && !!myInfo &&
                    <div className={tab === "referral" ? "active" : ""} onClick={() => setTab("referral")}>
                        Referral code
                    </div>
                }
            </Toggle>
            <ComboBox display={{ sm: "none", xs: "block" }} values={{
                held: `Coins held${coinsHeld.length ? ` (${coinsHeld.length})` : ''}`, 
                replies: `Replies${chatList.length ? ` (${chatList.length})` : ''}`, 
                created: `Coins created${coinsCreated.length ? ` (${coinsCreated.length})` : ''}`, 
                followers: `Followers${followers.length ? ` (${followers.length})` : ''}`, 
                followees: `Following${followees.length ? ` (${followees.length})` : ''}`, 
                referral:  userAddress === walletAddress ? `Referral code` : undefined, 
            }} value={tab} onChange={setTab} />
            {
                tab === "held" &&
                <CardGrid2 my={2} pb={4}>
                    {
                        coinsHeld.map((item: any, index: number) => (
                            <BalanceCard item={item} key={`held-${index}`}/>
                        ))
                    }
                </CardGrid2>
            }
            {
                tab === "replies" &&
                <Box mt={2} pb={4}>
                    <Typography color="#E7E3D8" display="flex" alignItems="center" gap="4px" fontSize={13} pl={2}>
                        <InfoIcon sx={{ height: 18 }}/>
                        Only you can view your replies
                    </Typography>
                    <Box display="flex" flexDirection="column" gap="10px" my="1em">
                        {
                            chatList.length > 0 &&
                            chatList.map((chat: any, index: number) => (
                                <Chat data={chat} key={`chat-${index}`} />
                            ))
                        }
                    </Box>
                </Box>
            }
            {
                tab === "notification" &&
                <Box mt={2} pb={4}>
                    <Box display="flex" alignItems="center" gap="8px" p="16px" sx={{ borderRadius: '16px', background: '#00000030' }}>
                        <Avatar sx={{ width: '24px', height: '24px' }} src={`/favicon.ico`}/>
                        <Typography color="#E7E3D8" fontSize={12}>Someone Followed you</Typography>
                        <Typography ml="auto" color="#9E9E9E" fontSize={12}>{new Date().toLocaleString()}</Typography>
                    </Box>
                </Box>
            }
            {
                tab === "created" &&
                <CardGrid1 mt={2} pb={4}>
                    {
                        coinsCreated.map((item: any, index: number) => (
                            <TokenCard token={item} key={`created-${index}`}/>
                        ))
                    }
                </CardGrid1>
            }
            {
                tab === "followers" &&
                <Box mt={2} pb={4} display="grid" gap="10px" gridTemplateColumns={{ md: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }}>
                    {
                        followers.map((item: any, index: number) => (
                            <Box display="flex" justifyContent={{ sm: 'flex-start', xs: 'center' }} alignItems="center" gap="8px" p="0 16px" key={`follower-${index}`}>
                                <User user={item.user} address={item.followerId} color="#E7E3D8" mr={0} />
                                <Typography color="#9E9E9E" fontSize={12}> — {item.followee?.followers ?? 0} followers</Typography>
                            </Box>
                        ))
                    }
                </Box>
            }
            {
                tab === "followees" &&
                <Box mt={2} pb={4} display="grid" gap="10px" gridTemplateColumns={{ md: 'repeat(4, 1fr)', sm: 'repeat(2, 1fr)', xs: 'repeat(1, 1fr)' }}>
                    {
                        followees.map((item: any, index: number) => (
                            <Box display="flex" justifyContent={{ sm: 'flex-start', xs: 'center' }} alignItems="center" gap="8px" p="0 16px" key={`followee-${index}`}>
                                <User user={item.user} address={item.followeeId} color="#E7E3D8" mr={0} />
                                <Typography color="#9E9E9E" fontSize={12}> — {item.followee?.followers ?? 0} followers</Typography>
                                {
                                    userAddress===walletAddress && 
                                    <Button onClick={() => handleUnfollow(item.followeeId)}>Unfollow</Button>
                                }
                            </Box>
                        ))
                    }
                </Box>
            }
            {
                tab === "referral" && userAddress === walletAddress &&
                <Box mt={4} pb={4} display="flex" alignItems="center" flexDirection="column">
                    <Typography color="white" fontSize="2em" fontFamily="Riffic Free">Generate yor referral code</Typography>
                    <Typography color="white" fontSize="1em" mt={1}>When someone generates volume or launches a token using your referral code YOU earn points!</Typography>
                    <Typography color="white" fontSize="1em" mt={1} mb={4}>Unlimited number of referrals</Typography>
                    <ReferralButton>
                        <Typography color="white" fontSize="1.2em" fontFamily="Riffic Free">{referralLink}</Typography>
                        <IconButton sx={{ p: 0, width: 'fit-content', ml: 1 }} onClick={handleCopy}>
                            <Tooltip
                                open={copied}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied"
                                placement="top"
                                arrow
                            >
                                <CopyIcon sx={{ color: "white" }} />
                            </Tooltip>
                        </IconButton>
                    </ReferralButton>
                    <Typography color="white" fontSize="1.2em" fontFamily="Riffic Free" mt={4} display="flex" alignItems="center" gap="6px">
                        <FavoriteIcon />
                        You have earned {priceFormatter(points?.total, 2)} POINTS
                    </Typography>
                    <Typography color="white" fontSize="1.2em" fontFamily="Riffic Free" mt={2} display="flex" alignItems="center" gap="6px">
                        <FavoriteIcon />
                        You have earned {priceFormatter(points?.earnings, 2)} POINTS from referrals
                    </Typography>
                    {
                        refferals > 0 &&
                        <Typography color="white" fontSize="1.2em" fontFamily="Riffic Free" mt={2} display="flex" alignItems="center" gap="6px">
                            <img src={usersIcon} />
                            You have successfully referred {refferals} users
                        </Typography>
                    }
                </Box>
            }
        </PageBox>
    )
}