import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme, useMediaQuery } from '@mui/material';
import { Alert, Avatar, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, LinearProgressProps, Pagination, PaginationItem, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from "@web3modal/ethers/react";
import HashLinkObserver from 'react-hash-link';
import LinkIcon from '@mui/icons-material/OpenInNewOutlined';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers';
import { NumericFormat } from "react-number-format";
import fairLaunchFactoryABI from "../../abis/FairLaunchFactoryABI.json";
import routerABI from "../../abis/IUniswapV2Router02.json";
import { IPFS_GATEWAY_URL, PULSECHAIN_CHAIN_ID, PULSECHAIN_CONTRACT_ADDRESS, PULSECHAIN_RPC_URL, PULSECHAIN_SCAN_URL, SERVER_URL, TARGET_MARKET_CAP, TOTAL_SUPPLY } from "../../abis/constants";
import PageBox from "../../components/layout/pageBox";
import TokenLogo from "../../components/tokenLogo";
import { priceFormatter } from "../../components/tvchart/chart";
import Toggle from "../../components/toggle";
import { BootstrapInput } from "../createCurvs";
import Chat from "../../components/cards/chat";
import { Creator, User, UserName } from "../../components/cards/user";
import abiERC20 from '../../abis/erc20.json'
import { socket } from "../../utils/socket";

import repliesIcon from '../../assets/images/replies.png';
import marketcapIcon from '../../assets/images/marketcap.png';
import TelegramIcon from '../../assets/images/telegram.svg';
import TwitterIcon from '../../assets/images/x.svg';
import WebsiteIcon from '../../assets/images/website.svg';
import switchIcon from '../../assets/images/switch.svg';
import resetIcon from '../../assets/images/reset.svg';
import plsIcon from '../../assets/images/wpls.png';
import dex9inchIcon from '../../assets/images/dex-9inch.png';
import dex9mmIcon from '../../assets/images/dex-9mm.png';
import dexPulsexIcon from '../../assets/images/dex-pulsex.png';
import MetamaskIcon from '../../assets/images/metamask.svg';

import { TVChartContainer as TVChartContainerAdvanced } from '../../components/tvchart';
import { StyledLinearProgress, TradeBox, SwapBox, CurrencyInput, SocialLink, StyledBadge, HolderBox, Banner, MetamaskButton } from "./DetailsPageStyles";

const PERIODS = [
    '1m', '5m', '15m', '30m', '1h', '4h', '12h', '1D', '1W', '1M'
]

const PERIOD_TO_MINUTES: any = {
    '1m': 1,
    '5m': 5,
    '15m': 15,
    '30m': 30,
    '1h': 60,
    '4h': 240,
    '12h': 720,
    '1D': 1440,
    '1W': 10080,
    '1M': 43200,
}

interface Candle {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ position: 'relative' }}>
            <StyledLinearProgress variant="determinate" {...props} />
            <Typography variant="body2" color="white" fontSize="12px" sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, textAlign: 'center', lineHeight: '24px' }}>{`${Math.round(props.value)}%`}</Typography>
        </Box>
    );
}

export default function DetailPage() {
    const { chain, id } = useParams();
    const [detailData, setDetailData] = React.useState<any>(null);
    const [chatList, setChatList] = React.useState<any[]>([]);
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [king, setKing] = React.useState<any>()
    // const [value, setValue] = React.useState('1');
    const [holders, setHolders] = React.useState([]);
    const [amountIn, setAmountIn] = React.useState<string>();
    // const [type, setType] = React.useState("BUY");
    const [estimateAmount, setEstimateAmount] = React.useState<string>();
    const [modal, showModal] = React.useState<string>();
    const [comment, setComment] = React.useState("");
    const [lpBalance, setLPBalance] = React.useState(0);
    const [maxBuyPercent, setMaxBuyPercent] = React.useState(0n);
    // const [maxAmount, setMaxAmount] = React.useState('0');
    // const [launched, setLaunched] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [mode, setMode] = React.useState('threads');
    const [tradeType, setTradeType] = React.useState('buy');
    const [exactInput, setExactInput] = React.useState(true);
    // const [candleList, setCandleList] = React.useState<any[]>()
    const [poolInfo, setPoolInfo] = React.useState<any>()
    const [error, setError] = React.useState<string>()
    const [replyId, setReplyId] = React.useState<number>()
    const [selectedDex, selectDex] = React.useState<DexKey>()

    const [marketCap, setMarketCap] = useState<number>()
    const [now, setNow] = useState(0)
    const [pageOfTrades, setPageOfTrades] = useState(1)

    const { address, chainId } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { switchNetwork } = useSwitchNetwork()

    // const [signer, setSigner] = React.useState(null as any);
    const [plsBalance, setPlsBalance] = React.useState(0n);
    const [tokenBalance, setTokenBalance] = React.useState(0n);
    const [tokenAllowance, setTokenAllowance] = React.useState(0n);

    // const infuraProjectId = "6cdb70a0884b4131a80a7fec5289acec";

    const isMobile = useMediaQuery('(max-width: 1024px)');

    type DexKey = '9INCH' | 'PulseX' | '9mm';
    const NineInchLink = "https://app.9inch.io/?chain=pulsechain&inputCurrency=PLS&outputCurrency="
    const PulseXLink = "https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency="
    const NineMMLink = "https://swap.9mm.pro/?outputCurrency="

    const DEX_LINKS = {
        '9INCH': NineInchLink,
        'PulseX': PulseXLink,
        '9mm': NineMMLink
    }

    const DEX_LOGOS = {
        '9INCH': dex9inchIcon,
        'PulseX': dexPulsexIcon,
        '9mm': dex9mmIcon
    }

    const WPLS = '0xA1077a294dDE1B09bB078844df40758a5D0f9a27'

    const DEX_ROUTERS = {
        '9INCH': '0xeB45a3c4aedd0F47F345fB4c8A1802BB5740d725',
        'PulseX': '0x165C3410fC91EF562C50559f7d2289fEbed552d9',
        '9mm': '0xcC73b59F8D7b7c532703bDfea2808a28a488cF47'
    }

    useEffect(() => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    }, [isMobile]);

    useEffect(() => {
        if (chainId != PULSECHAIN_CHAIN_ID)
            switchNetwork(PULSECHAIN_CHAIN_ID)
    }, [chainId])

    const launched = useMemo(() => poolInfo?.launched || !!detailData?.launchedAt, [poolInfo, detailData])

    const provider = new ethers.JsonRpcProvider(PULSECHAIN_RPC_URL)

    const tokenContract = useMemo(() => {
        if (id && provider)
            return new ethers.Contract(id, abiERC20, provider)
    }, [provider, id])

    const factoryContract = useMemo(() => {
        return new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, provider)
    }, [provider])

    const routerContract = useMemo(() => {
        if (selectedDex === undefined)
            return undefined
        return new ethers.Contract(DEX_ROUTERS[selectedDex], routerABI, provider)
    }, [provider, selectedDex])

    const fetchContractData = useCallback(() => {
        try {
            if (address) {
                provider.getBalance(address as `0x${string}`).then(balance => {
                    setPlsBalance(balance)
                }).catch(() => { })
            }
            if (tokenContract) {
                if (address) {
                    tokenContract.balanceOf(address).then(balance => {
                        setTokenBalance(balance)
                    }).catch(() => { })
                    if (selectedDex === undefined)
                        tokenContract.allowance(address, PULSECHAIN_CONTRACT_ADDRESS).then(allowance => {
                            setTokenAllowance(allowance)
                        }).catch(() => { })
                    else
                        tokenContract.allowance(address, DEX_ROUTERS[selectedDex]).then(allowance => {
                            setTokenAllowance(allowance)
                        }).catch(() => { })
                }
                tokenContract.balanceOf(factoryContract.target).then(balance => {
                    setLPBalance(Number(ethers.formatEther(balance)))
                }).catch(() => { })
            }
            if (factoryContract && detailData) {
                factoryContract.MAX_BUY_PERCENT().then(percent => setMaxBuyPercent(percent))
                factoryContract.tokenPools(detailData.tokenAddress).then((poolInfo: any) => {
                    setPoolInfo(poolInfo)
                }).catch(() => { })
                factoryContract.getTokenMarketCap(detailData.tokenAddress).then(mc => {
                    setMarketCap(Number(mc))
                }).catch(() => { })
            }
        } catch (ex) {

        }
    }, [address, walletProvider, tokenContract, factoryContract, detailData, selectedDex])

    useEffect(() => {
        try {
            if (poolInfo && amountIn) {
                if (launched) {
                    if (selectedDex === undefined || !routerContract)
                        throw Error("Already launched!")
                    if (tradeType === "buy") {
                        if (exactInput) {
                            const amountInWei = ethers.parseEther(amountIn)
                            if (amountInWei > plsBalance)
                                throw Error("Insufficient PLS balance")
                            routerContract.getAmountsOut(amountInWei, [WPLS, detailData.tokenAddress]).then(amountsOut => {
                                setEstimateAmount(ethers.formatEther(amountsOut[1]))
                            }).catch(() => { })
                        } else {
                            const amountOutWei = ethers.parseEther(amountIn)
                            routerContract.getAmountsIn(amountOutWei, [WPLS, detailData.tokenAddress]).then(amountsIn => {
                                if (amountsIn[0] > plsBalance)
                                    throw Error("Insufficient PLS balance")
                                setEstimateAmount(ethers.formatEther(amountsIn[0]))
                            }).catch(() => { })
                        }
                    } else if (tradeType === "sell") {
                        const amountInWei = ethers.parseEther(amountIn)
                        if (amountInWei > tokenBalance)
                            throw Error(`Insufficient ${detailData?.tokenSymbol ?? 'Token'} balance.`)
                        routerContract.getAmountsOut(amountInWei, [detailData.tokenAddress, WPLS]).then(amountsOut => {
                            setEstimateAmount(ethers.formatEther(amountsOut[1]))
                        }).catch(() => { })
                    }
                } else {
                    if (tradeType === "buy") {
                        if (exactInput) {
                            const amountInWei = ethers.parseEther(amountIn) * 97n / 100n
                            if (amountInWei > poolInfo.virtualEthReserve * maxBuyPercent / 10000n)
                                throw Error(`Maximum: ${priceFormatter(ethers.formatEther(poolInfo.virtualEthReserve * maxBuyPercent / 10000n), 2)} PLS`)
                            if (amountInWei > plsBalance)
                                throw Error("Insufficient PLS balance")
                            setEstimateAmount(ethers.formatEther(amountInWei * poolInfo.virtualTokenReserve / poolInfo.virtualEthReserve))
                        } else {
                            const amountInWei = ethers.parseEther(amountIn)
                            const estAmount = amountInWei * 100n * poolInfo.virtualEthReserve / poolInfo.virtualTokenReserve / 97n
                            if (estAmount * 97n / 100n > poolInfo.virtualEthReserve * maxBuyPercent / 10000n)
                                throw Error(`Maximum: ${priceFormatter(ethers.formatEther(poolInfo.virtualTokenReserve * maxBuyPercent / 10000n), 2)} ${detailData.tokenSymbol}`)
                            setEstimateAmount(ethers.formatEther(estAmount))
                            if (estAmount > plsBalance)
                                throw Error(`Insufficient PLS balance.`)
                        }
                    } else if (tradeType === "sell") {
                        const amountInWei = ethers.parseEther(amountIn)
                        if (amountInWei > poolInfo.tokenReserve)
                            throw Error("Amount exceeds current reserves.")
                        if (amountInWei > tokenBalance)
                            throw Error(`Insufficient ${detailData?.tokenSymbol ?? 'Token'} balance.`)
                        setEstimateAmount(ethers.formatEther(amountInWei * 94n * poolInfo.virtualEthReserve / poolInfo.virtualTokenReserve / 100n))
                    }
                }
            } else
                setEstimateAmount(undefined)
            setError(undefined)
        } catch (err: any) {
            setEstimateAmount(undefined)
            setError(err.message)
        }
    }, [amountIn, poolInfo, tradeType, exactInput, detailData, launched, selectedDex, routerContract])

    const handlePost = useCallback(async () => {
        if (id === "" || address === undefined || comment === "" || chain === "") return;

        setIsLoading(true)

        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const signer = await ethersProvider.getSigner()

        const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
        const signature = await signer.signMessage(msg)

        const body = {
            tokenAddress: id,
            replyAddress: address,
            comment: comment,
            network: chain,
            replyId,
            msg,
            signature,
        }
        try {
            await axios.post(`${SERVER_URL}/chats/reply`, body)
                .then(function (response) {
                    setChatList(response.data);
                    setComment("");
                    showModal(undefined)
                    getDetails().then(() => { })
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }, [replyId, address, comment, chain, walletProvider])

    const getDetails = async () => {
        try {
            await axios.get(`${SERVER_URL}/tokens/${chain}/${id}`)
                .then(function ({ data }) {
                    console.log(data)
                    setKing(data.king)
                    setDetailData(data.tokenDetils)
                    setChatList(data.chatList);
                    setHolders(data.holdersDetails)
                    setTradeData(data.trades)
                    // funcSetLPBalance(data.tokenDetils);
                    // funcGetLaunched(data.tokenDetils);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {

        }
    }

    const showReply = (replyId?: number) => {
        setReplyId(replyId)
        showModal('reply')
    }

    const approveHandler = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!detailData || tradeType !== "sell" || !amountIn)
                throw Error("");
            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await ethersProvider.getSigner()
            if (!signer)
                throw Error("Connect wallet");
            const tokenContract = new ethers.Contract(detailData.tokenAddress, abiERC20, signer);
            const amountInput = ethers.parseEther(Number(amountIn).toFixed(18))
            // const approveAmount = amountInput
            const approveAmount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
            const tx = await tokenContract.approve(selectedDex ? DEX_ROUTERS[selectedDex] : PULSECHAIN_CONTRACT_ADDRESS, approveAmount)
            await tx.wait()
            toast.success("Successfully approved!");
        } catch (error: any) {
            const messageError = error?.shortMessage || error?.data?.message;
            toast.error(messageError);
        }
        setIsLoading(false);
    }, [detailData, amountIn, tradeType, walletProvider, amountIn, selectedDex])

    const swapHandler = useCallback(async () => {
        setIsLoading(true);
        try {
            if (!detailData || !tokenContract || !amountIn)
                throw Error("");
            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await ethersProvider.getSigner()
            if (!signer)
                throw Error("Connect wallet");
            // const balanceBefore = await tokenContract.balanceOf(signer.address)
            // const plsBefore = await provider.getBalance(signer.address)
            // const amountOutput = tradeType === "buy"
            //     ? ethers.parseEther(String(exactInput ? estimateAmount : amountIn))
            //     : ethers.parseEther(String(estimateAmount))
            // const stableUnit = await factoryContract.STABLE_UNIT()
            // const poolInfo = await factoryContract.tokenPools(id)
            // const poolInfo = await factoryContract.tokenPools(detailData.tokenAddress)
            // let receipt = undefined
            if (launched) {
                if (selectedDex) {
                    const slippage = 10n // 0.1%
                    const deadline = Math.floor(Date.now() / 1000) + 180
                    const routerContract = new ethers.Contract(DEX_ROUTERS[selectedDex], routerABI, signer)
                    if (tradeType === "buy") {
                        if (exactInput) {
                            const tx = await routerContract.swapExactETHForTokensSupportingFeeOnTransferTokens(
                                ethers.parseEther(String(estimateAmount)) * (10000n - slippage) / 10000n,
                                [WPLS, detailData.tokenAddress],
                                address,
                                deadline,
                                { value: ethers.parseEther(amountIn) }
                            )
                            await tx.wait()
                        } else {
                            const tx = await routerContract.swapETHForExactTokens(
                                ethers.parseEther(amountIn),
                                [WPLS, detailData.tokenAddress],
                                address,
                                deadline,
                                { value: ethers.parseEther(String(estimateAmount)) * (10000n + slippage) / 10000n }
                            )
                            await tx.wait()
                        }
                    } else {
                        const tx = await routerContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
                            ethers.parseEther(amountIn),
                            ethers.parseEther(String(estimateAmount)) * (10000n - slippage) / 10000n,
                            [detailData.tokenAddress, WPLS],
                            address,
                            deadline
                        )
                        await tx.wait()
                    }
                }
            } else {
                const amountInput = tradeType === "buy"
                    ? ethers.parseEther((exactInput ? amountIn : estimateAmount) ?? '0')
                    : ethers.parseEther(amountIn)
                const factoryContract = new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, signer);
                if (tradeType === "buy") {
                    const firstBuyFee = await factoryContract.getFirstBuyFee(detailData.tokenAddress)
                    const totalAmount = amountInput + firstBuyFee;
                    const userEthBalance = await provider.getBalance(address as `0x${string}`);
                    console.log("totalAmount", totalAmount)
                    console.log("userEthBalance", userEthBalance)
                    if (userEthBalance < totalAmount) {
                        toast.error("Insufficient balance to cover the first buyer fee of $3")
                        setIsLoading(false);
                        return
                    }
                    const tx = await factoryContract.buyTokens(detailData.tokenAddress, amountInput, { value: totalAmount })
                    await tx.wait()
                } else {
                    const token = new ethers.Contract(detailData?.tokenAddress, abiERC20, signer)
                    const allowance = await token?.allowance(signer.address, PULSECHAIN_CONTRACT_ADDRESS)
                    if (allowance < amountInput) {
                        const approveAmount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                        const tx = await token?.approve(PULSECHAIN_CONTRACT_ADDRESS, approveAmount)
                        await tx?.wait()
                    }
                    const tx = await factoryContract.sellTokens(detailData.tokenAddress, amountInput)
                    await tx.wait()
                }
            }

            // const logTrade = receipt.logs.find((log: any) => (tradeType==="buy" && log.eventName==="BuyTokens") || (tradeType==="sell" && log.eventName==="SellTokens"))
            // const logLaunch = receipt.logs.find((log: any) => tradeType==="buy" && log.eventName==="LiquidityAdded")
            // const { launched } = await factoryContract.tokenPools(id)
            // const balanceAfter = await tokenContract.balanceOf(signer.address)
            // const tokenPrice = await factoryContract.getPrice(detailData.tokenAddress)
            // const marketcap = await factoryContract.getTokenMarketCap(detailData.tokenAddress)
            // const ethPrice = await factoryContract.getETHPriceByUSD()
            // const tokenPriceUSD = ethers.formatEther(BigInt(tokenPrice) * BigInt(ethPrice) / stableUnit)
            // const body = {
            //     type: "swap",
            //     data: {
            //         tokenName: detailData.tokenName,
            //         tokenSymbol: detailData.tokenSymbol,
            //         tokenAddress: detailData.tokenAddress,
            //         tokenImage: detailData.tokenImage,
            //         creatorAddress: detailData.creatorAddress,
            //         swapperAddress: signer.address,
            //         type: tradeType.toUpperCase(),
            //         ethAmount: ethers.formatEther(logTrade.args.plsAmount), // ethers.formatEther(tradeType === "buy" ? amountInput : amountOutput),
            //         tokenAmount: ethers.formatEther(logTrade.args.tokenAmount), // ethers.formatEther(tradeType === "sell" ? amountInput : amountOutput),
            //         launched: !!logLaunch,
            //         network: chain,
            //         marketcap: String(marketcap),
            //         txHash: receipt.hash,
            //         tokenPrice: tokenPriceUSD,
            //         tokenBalanceOfUser: ethers.formatEther(balanceAfter)
            //     }
            // }
            setAmountIn(undefined)
            // setTokenBalance(Number(ethers.formatEther(balanceAfter)))
            // socket.emit("message", JSON.stringify(body))
            toast.success("Transaction success");
            fetchContractData()
            getDetails().then(() => { })
            // getTrades().then(() => { })
            // getChartData(activePeriod)
        } catch (error: any) {
            console.log({
                error
            });
            const messageError = error?.shortMessage || error?.data?.message;
            toast.error(messageError);
        }
        setIsLoading(false);
    }, [detailData, address, provider, amountIn, tradeType, exactInput, tokenContract, walletProvider, chain, launched, selectedDex])

    // React.useEffect(() => {
    //     getDetails()
    //     getTrades()
    // }, []);

    // React.useEffect(() => {
    //     getChartData(activePeriod)
    // }, [activePeriod]);

    useEffect(() => {
        getDetails()
        socket.on('message', (data: any) => {
            getDetails()
            // getChartData(activePeriod)
        })
        const intervalId = setInterval(() => setNow(Date.now()), 1000)
        return () => clearInterval(intervalId)
    }, [id])

    useEffect(() => {
        // getLastPrice()
        // getTrades().then(() => { })
        fetchContractData()
    }, [now])

    // const candle: any = useMemo(() => candleHover ?? candleCurrent, [candleHover, candleCurrent])
    // const isUp = useMemo(() => candle?.open <= candle?.close, [candle])
    const pageSize = isMobile ? 5 : 20
    const pagesOfTrades = useMemo(() => tradeData?.length ? Math.floor(tradeData.length / pageSize) + (tradeData.length % pageSize == 0 ? 0 : 1) : 0, [tradeData])
    const parseLink = (domain: string, link?: string) => {
        if (!link)
            return undefined
        if (domain) {
            if (link.startsWith(`https://${domain}/`))
                return link
            if (link.startsWith(`${domain}/`) || link.startsWith(`/${domain}/`))
                return `https://${link}`
            return `https://${domain}/${link}`
        } else {
            if (link.startsWith(`https://`))
                return link
            return `https://${link}`
        }
    }

    const telegramLink = useMemo(() => {
        return parseLink('t.me', detailData?.telegramLink)
    }, [detailData])

    const twitterLink = useMemo(() => {
        return parseLink('x.com', detailData?.twitterLink)
    }, [detailData])

    const webLink = useMemo(() => {
        return parseLink('', detailData?.webLink)
    }, [detailData])

    const dexList: DexKey[] | undefined = useMemo(() => {
        if (detailData && detailData.pairAddresses)
            return Object.keys(detailData.pairAddresses) as DexKey[]
        if (poolInfo?.bitmapRouters) {
            const routers: DexKey[] = []
            const ROUTERS: DexKey[] = ["9INCH", "9mm", "PulseX"]
            for (const i in ROUTERS) {
                if ((Number(poolInfo.bitmapRouters) >> Number(i)) & 0x1)
                    routers.push(ROUTERS[i])
            }
            if (routers.length)
                return routers
        }
        return undefined
    }, [detailData, poolInfo])

    const realMarketCap = useMemo(() => launched ? marketCap : detailData?.marketcap ?? marketCap, [marketCap, detailData, launched])

    function AddTokenToMetamask() {

    }

    const HistoryBox = (props: any) => (
        <Box {...props}>
            <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
                <Toggle>
                    <div className={mode === "threads" ? "active" : ""} onClick={() => setMode("threads")}>Thread</div>
                    {
                        tradeData?.length > 0 &&
                        <div className={mode === "trades" ? "active" : ""} onClick={() => setMode("trades")}>Trades</div>
                    }
                </Toggle>
                {mode === "threads" && (
                    <Button variant="text" color="warning" sx={{ borderColor: 'transparent', height: 'fit-content', alignSelf: 'flex-end', mt: { sm: 0, xs: '1em' } }} onClick={() => showReply()}>
                        <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={repliesIcon} />
                        New {address === detailData?.creatorAddress ? 'comment' : 'reply'}
                    </Button>
                )}
            </Box>

            {mode === "threads" && (
                <Box display="flex" flexDirection="column" gap="8px" py="1.5em">
                    <Chat token={detailData} />
                    {chatList?.length > 0 && chatList.map((chat) => (
                        <Chat key={chat.id} data={chat} token={detailData} onReply={() => showReply(chat.id)} repliedTo={chatList.find(c => chat.id !== c.id && chat.code?.startsWith(c.code) && c.code.length + 11 === chat.code?.length)?.id} />
                    ))}
                </Box>
            )}

            {mode === "trades" && (
                <>
                    <Box display="flex" flexDirection="column" gap="8px" py="1.5em">
                        <TradeBox style={{ paddingTop: "1em" }}>
                            <Typography color="white" display={{ sm: 'block', xs: 'none' }}>Maker</Typography>
                            <Typography color="white">Type</Typography>
                            <Typography color="white">PLS</Typography>
                            <Typography color="white">{detailData.tokenSymbol}</Typography>
                            <Typography color="white">Date</Typography>
                            <Typography color="white" display={{ sm: 'block', xs: 'none' }}>Transaction</Typography>
                        </TradeBox>
                        {tradeData?.length > 0 && tradeData.sort((a: any, b: any) => b.date - a.date).filter((_: any, i: number) => i < pageOfTrades * pageSize && i >= (pageOfTrades - 1) * pageSize).map((trade: any) => (
                            <TradeBox key={trade.txHash}>
                                <Box display={{ sm: 'flex', xs: 'none' }} alignItems="center">
                                    <User user={trade.user} address={trade.swapperAddress} color="#9E9E9E" size={18} />
                                </Box>
                                <Typography color={trade.type === "BUY" ? "#26a69a" : "#ef5350"} fontSize="small">{trade.type}</Typography>
                                <Typography color="#9E9E9E" fontSize="small">{priceFormatter(trade.ethAmount, 2, true, true)}</Typography>
                                <Typography color="#9E9E9E" fontSize="small">{priceFormatter(trade.tokenAmount, 2, true, true)}</Typography>
                                <Typography color="#9E9E9E" fontSize="small">{new Date(trade.date * 1000).toLocaleString('en-US', { month: 'short', year: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}</Typography>
                                <Box display={{ sm: 'block', xs: 'none' }}>
                                    <Link style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} to={`${PULSECHAIN_SCAN_URL}/tx/${trade.txHash}`} target="_blank">
                                        <Typography color="#9E9E9E" fontSize="small">{trade.txHash.slice(0, 5)}...{trade.txHash.slice(-6)}</Typography>
                                        <LinkIcon sx={{ color: "#9E9E9E", fontSize: 14 }} />
                                    </Link>
                                </Box>
                            </TradeBox>
                        ))}
                    </Box>
                    <Box>
                        <Pagination
                            style={{ margin: 'auto', width: 'fit-content' }}
                            variant="text"
                            shape="circular"
                            count={pagesOfTrades}
                            page={pageOfTrades}  // current page
                            onChange={(e, page) => setPageOfTrades(page)}  // handle page change
                            renderItem={(data) => (
                                <PaginationItem
                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...data}
                                />
                            )}
                        />
                    </Box>
                </>
            )}
        </Box>
    )

    return (
        <PageBox display="flex" justifyContent="space-between" gap="1.5em">
            <HashLinkObserver smoothScroll />
            {!!detailData?.tokenBanner && (
                <Banner src={`${IPFS_GATEWAY_URL}/${detailData.tokenBanner}`} />
            )}
            <div style={{ flex: 1, position: 'relative' }}>
                <Box display={{ sm: 'none', xs: 'flex' }} alignItems="center" mb={2} justifyContent="space-between">
                    <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', alignSelf: 'flex-start' }}>
                        <ArrowLeftIcon sx={{ color: '#9E9E9E', height: 24 }} />
                        <Typography sx={{ color: '#9E9E9E', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                    </Link>
                    <Box display="flex" alignItems="center" gap="1em">
                        {!!telegramLink && (
                            <SocialLink to={telegramLink} target="_blank"><img src={TelegramIcon} width={30} height={30} alt="telegram" /></SocialLink>
                        )}
                        {!!twitterLink && (
                            <SocialLink to={twitterLink} target="_blank"><img src={TwitterIcon} width={24} height={24} alt="twitter" /></SocialLink>
                        )}
                        {!!webLink && (
                            <SocialLink to={webLink} target="_blank"><img src={WebsiteIcon} width={24} height={24} alt="website" /></SocialLink>
                        )}
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb="2em" flexDirection={{ xs: 'column', sm: 'row' }} gap="1em" alignItems={{ xs: 'center', sm: 'flex-start' }}>
                    <Box>
                        <TokenLogo logo={detailData?.tokenImage} size="170px" />
                        <Box display={{ sm: "flex", xs: "none" }} alignItems="center" justifyContent="center" gap="1em" mt={1}>
                            {!!telegramLink && (
                                <SocialLink to={telegramLink} target="_blank"><img src={TelegramIcon} width={30} height={30} alt="telegram" /></SocialLink>
                            )}
                            {!!twitterLink && (
                                <SocialLink to={twitterLink} target="_blank"><img src={TwitterIcon} width={24} height={24} alt="twitter" /></SocialLink>
                            )}
                            {!!webLink && (
                                <SocialLink to={webLink} target="_blank"><img src={WebsiteIcon} width={24} height={24} alt="website" /></SocialLink>
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" pb={1} gap="0.5em" alignItems={{ xs: "center", sm: "flex-start" }}>
                        {
                            launched &&
                            <Box display="flex" gap="0.2rem" mb="20px">
                                <StyledBadge color="success" badgeContent="Launched" invisible={!launched} />
                            </Box>
                        }
                        <Box display="flex" gap="0.2rem">
                            <Typography fontSize={28} color="white" fontFamily="Riffic Free">{detailData?.tokenName}</Typography>
                        </Box>
                        <Box display="flex" gap="0.2rem" alignItems="center">
                            <Typography color="#9E9E9E" fontSize={14}>Ticker:</Typography>
                            <Typography color="#D9D9D9" fontSize={14}>{detailData?.tokenSymbol}</Typography>
                            <IconButton sx={{ p: 0, width: 'fit-content', ml: 1 }} onClick={() => copy(detailData?.tokenAddress)}>
                                <CopyIcon sx={{ color: "#9E9E9E", width: 14, height: 14 }} />
                            </IconButton>

                            <MetamaskButton onClick={async () => {
                                try {
                                    // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
                                    const wasAdded = await walletProvider! // Or window.ethereum if you don't support EIP-6963.
                                        .request({
                                            method: "wallet_watchAsset",
                                            params: {
                                                type: "ERC20",
                                                options: {
                                                    // The address of the token.
                                                    address: detailData.tokenAddress,
                                                    // A ticker symbol or shorthand, up to 5 characters.
                                                    symbol: detailData.tokenSymbol,
                                                    // The number of decimals in the token.
                                                    decimals: 18,
                                                    // A string URL of the token logo.
                                                    image: detailData.tokenImage,
                                                },
                                            },
                                        })

                                    if (wasAdded) {
                                        console.log("Thanks for your interest!")
                                    } else {
                                        console.log("Your loss!")
                                    }
                                } catch (error) {
                                    console.log(error)
                                }

                            }} style={{ width: 18, height: 18 }} src={MetamaskIcon} />

                        </Box>
                        <Box display="flex" gap="0.2rem">
                            <Creator token={detailData} />
                            <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                            <Typography color="#9E9E9E" fontSize={14}>creator</Typography>
                        </Box>
                        <Box display="flex" gap="0.2rem">
                            <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={repliesIcon} />
                            <Typography color="#D9D9D9" fontSize={14}>{detailData?.replies}</Typography>
                            <Typography color="#9E9E9E" fontSize={14}>replies</Typography>
                        </Box>
                        <Box display="flex" gap="0.2rem">
                            <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={marketcapIcon} />
                            <Typography color="#D9D9D9" fontSize={14}>${priceFormatter(realMarketCap, 2)}</Typography>
                            <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                            <Typography color="#9E9E9E" fontSize={14}>Market cap</Typography>
                        </Box>
                        {
                            !launched && !!dexList &&
                            <Box display="flex" gap="0.2rem">
                                {
                                    dexList.map(dex => (
                                        <Avatar src={DEX_LOGOS[dex]} sx={{ width: 24, height: 24 }} />
                                    ))
                                }
                                <Typography color="#9E9E9E" fontSize={14}>—</Typography>
                                <Typography color="#9E9E9E" fontSize={14}>DEX to launch</Typography>
                            </Box>
                        }
                    </Box>

                    <Box display={{ sm: "flex", xs: "none" }} flexDirection="column" justifyContent="space-between" alignItems="flex-end" pb={1} ml="auto">
                        <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftIcon sx={{ color: 'white', height: 24 }} />
                            <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                        </Link>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" gap="2em" flexDirection={{ md: 'row', sm: 'column', xs: 'column' }} mb={4}>
                    <Box flex="1" display="flex" flexDirection="column">
                        {
                            !launched &&
                            <Box display="flex" flexDirection="column" gap={1} mb="2em">
                                <Typography color="white" fontFamily="Riffic Free" fontSize="16px">Bonding curve progress:</Typography>
                                <LinearProgressWithLabel variant="determinate" value={launched ? 100 : (realMarketCap ?? 0) * 100 / TARGET_MARKET_CAP} style={{ borderRadius: '16px', height: '24px' }} />
                            </Box>
                        }
                        {
                            launched &&
                            <Box display="flex" gap="1em" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb="2em" alignItems={{ sm: "flex-end", xs: "stretch" }}>
                                <Toggle style={{ width: "auto" }}>
                                    <div className={selectedDex === undefined ? "active" : ""} onClick={() => selectDex(undefined)}>CoinQuest</div>
                                    {
                                        dexList?.map((dex: DexKey) =>
                                            <div className={selectedDex === dex ? "active" : ""} onClick={() => selectDex(dex)}>{dex}</div>
                                        )
                                    }
                                </Toggle>
                                {
                                    !!selectedDex &&
                                    <Link to={`${DEX_LINKS[selectedDex]}${id}`} target="_blank" style={{ textDecoration: 'none' }}>
                                        <Button variant="text" color="warning" sx={{ borderColor: 'transparent', display: "flex", alignItems: "center", justifyContent: "center", height: 'fit-content', width: '100%' }}>
                                            Trade {detailData?.tokenSymbol} on {selectedDex}
                                            <LinkIcon sx={{ color: "#e19428", height: 16 }} />
                                        </Button>
                                    </Link>
                                }
                            </Box>
                        }
                        <TVChartContainerAdvanced token={id} dex={selectedDex} />

                        {/* <TVChartContainer mb="2em">
                            <Box display="flex" gap="4px" borderBottom="1px solid #242733" p={1}>
                                {PERIODS.map(p => (
                                    <IntervalButton key={p} onClick={() => setActivePeriod(p)} className={p === activePeriod ? "active" : ""}>{p}</IntervalButton>
                                ))}
                            </Box>
                            <Box display="flex" gap="8px" sx={{ position: 'absolute', top: '38px', zIndex: 2 }} alignItems="flex-end" pl={1}>
                                <Typography fontSize="12px" color="#76808f">{new Date(candle?.time * 1000).toLocaleString()}</Typography>
                                <Typography fontSize="12px" color="#76808f">Open:</Typography>
                                <Typography color={isUp ? '#26a69a' : '#ef5350'}>{priceFormatter(candle?.open ?? 0)}</Typography>
                                <Typography fontSize="12px" color="#76808f">High:</Typography>
                                <Typography color={isUp ? '#26a69a' : '#ef5350'}>{priceFormatter(candle?.high ?? 0)}</Typography>
                                <Typography fontSize="12px" color="#76808f">Low:</Typography>
                                <Typography color={isUp ? '#26a69a' : '#ef5350'}>{priceFormatter(candle?.low ?? 0)}</Typography>
                                <Typography fontSize="12px" color="#76808f">Close:</Typography>
                                <Typography color={isUp ? '#26a69a' : '#ef5350'}>{priceFormatter(candle?.close ?? 0)}</Typography>
                            </Box>
                            <TVChartSimple data={candleList} current={candleCurrent} onHover={(candle: any) => setCandleHover(candle)} />
                        </TVChartContainer> */}

                        <HistoryBox display={{ xs: 'none', sm: 'block' }} mt="2em" />
                    </Box>

                    <Box sx={{ width: { md: '370px', sm: '100%', xs: '100%' } }}>
                        <SwapBox className={isLoading || launched && selectedDex === undefined ? "disabled" : ""}>
                            <Box display="flex" alignItems="center" gap={2}>
                                {
                                    launched && selectedDex !== undefined &&
                                    <Avatar src={DEX_LOGOS[selectedDex]} />
                                }
                                <Typography fontSize={32} color="white" fontFamily="Riffic Free">
                                    {launched ? selectedDex === undefined ? "Launched!" : selectedDex : "Trade now!"}
                                </Typography>
                            </Box>
                            <Toggle style={{ width: "inherit" }}>
                                <div className={tradeType === "buy" ? "active" : ""} onClick={() => setTradeType("buy")}>Buy</div>
                                <div className={tradeType === "sell" ? "active" : ""} onClick={() => setTradeType("sell")}>Sell</div>
                            </Toggle>
                            <Box display="flex" flexDirection="column" alignContent="center">
                                {tradeType === "buy" && (
                                    <Button className="medium" onClick={() => {
                                        setExactInput(!exactInput);
                                        setAmountIn(estimateAmount);
                                    }}>
                                        <Avatar src={switchIcon} sx={{ width: 20, height: 20 }} />
                                        Switch to {exactInput ? detailData?.tokenSymbol : 'PLS'}
                                    </Button>
                                )}
                            </Box>
                            <FormControl variant="standard">
                                <CurrencyInput>
                                    <Typography component="span" className="balance">
                                        Balance: {priceFormatter(ethers.formatEther(tradeType === "buy" && exactInput ? plsBalance : tokenBalance))}
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap="4px">
                                        <NumericFormat
                                            placeholder="0.0"
                                            thousandSeparator
                                            valueIsNumericString
                                            value={amountIn ?? ''}
                                            onValueChange={(values) => setAmountIn(values.value)}
                                        />
                                        <Typography color="white">{exactInput && tradeType === "buy" ? 'PLS' : detailData.tokenSymbol.toUpperCase()}</Typography>
                                        {(exactInput && tradeType === "buy") ? (
                                            <Avatar src={plsIcon} sx={{ width: 32, height: 32 }} />
                                        ) : (
                                            <TokenLogo logo={detailData?.tokenImage} size="32px" style={{ width: '32px', height: '32px', borderRadius: '20px' }} />
                                        )}
                                    </Box>
                                </CurrencyInput>
                            </FormControl>
                            {(exactInput || tradeType === "sell") && (
                                <Box display="flex" gap="4px" flexWrap="wrap">
                                    <Button className="medium small" onClick={() => setAmountIn(undefined)}>
                                        <Avatar src={resetIcon} sx={{ width: 16, height: 16 }} />
                                        Reset
                                    </Button>
                                    {tradeType === "buy" && (
                                        <>
                                            <Button className="medium small" onClick={() => setAmountIn('100000')}>100K PLS</Button>
                                            <Button className="medium small" onClick={() => setAmountIn('500000')}>500K PLS</Button>
                                            <Button className="medium small" onClick={() => setAmountIn('1000000')}>1M PLS</Button>
                                        </>
                                    )}
                                    {tradeType === "sell" && (
                                        <>
                                            <Button className="medium small" onClick={() => setAmountIn(tokenBalance ? ethers.formatEther(tokenBalance * 25n / 100n) : undefined)}>25%</Button>
                                            <Button className="medium small" onClick={() => setAmountIn(tokenBalance ? ethers.formatEther(tokenBalance * 50n / 100n) : undefined)}>50%</Button>
                                            <Button className="medium small" onClick={() => setAmountIn(tokenBalance ? ethers.formatEther(tokenBalance * 75n / 100n) : undefined)}>75%</Button>
                                            <Button className="medium small" onClick={() => setAmountIn(ethers.formatEther(tokenBalance))}>100%</Button>
                                        </>
                                    )}
                                </Box>
                            )}
                            {!!estimateAmount && (
                                <Typography color="wheat">{priceFormatter(estimateAmount)} {exactInput && tradeType === "buy" ? detailData?.tokenSymbol.toUpperCase() : 'PLS'}</Typography>
                            )}
                            {!!error && (
                                <Alert severity="error" sx={{ borderRadius: '16px' }}>{error}</Alert>
                            )}
                            {tradeType === "sell" && amountIn && tokenAllowance < ethers.parseEther(amountIn) ? (
                                <Button fullWidth sx={{ borderRadius: '16px', fontSize: '20px', py: '12px', textTransform: 'none' }} disabled={isLoading || !!error || !amountIn} onClick={approveHandler}>
                                    Enable Trade {isLoading && <CircularProgress size={18} style={{ color: "white", marginLeft: "1em" }} />}
                                </Button>
                            ) : (
                                <Button fullWidth sx={{ borderRadius: '16px', fontSize: '20px', py: '12px', textTransform: 'none' }} disabled={isLoading || !!error || !amountIn} onClick={swapHandler}>
                                    Place Trade {isLoading && <CircularProgress size={18} style={{ color: "white", marginLeft: "1em" }} />}
                                </Button>
                            )}
                        </SwapBox>

                        {
                            !!detailData?.tokenDescription &&
                            <Typography mt="2em" mb={'1em'} color="#E7E3D8" sx={{ fontSize: { sm: '14px', xs: '12px' } }}>
                                {detailData.tokenDescription}
                            </Typography>
                        }

                        {
                            !launched
                                ? <>
                                    {
                                        !!king && king.tokenAddress === detailData.tokenAddress
                                            ? <Box display="flex" gap={1} mt="2em">
                                                <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={marketcapIcon} />
                                                <Typography color="#FF9900" fontSize="16px">Crowned king of the hill on {new Date(king.createdAt).toLocaleString('en-US', { month: 'short', year: 'numeric', day: 'numeric' })}</Typography>
                                            </Box>
                                            : <Box display="flex" flexDirection="column" gap={1} mt="2em">
                                                <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                                                    <Typography color="white" fontFamily="Riffic Free" fontSize="16px">King of the hill progress:</Typography>
                                                    <Typography color="#FF9900" fontSize="14px">{priceFormatter((realMarketCap && king ? realMarketCap / king.token.marketcap : 0) * 100, 2)}%</Typography>
                                                </Box>
                                                <LinearProgress variant="determinate" value={(realMarketCap && king ? Math.min(1, realMarketCap / king.token.marketcap) : 0) * 100} color="warning" style={{ borderRadius: '16px', height: '6px' }} />
                                                <Typography color="#E7E3D8" fontSize="13px">dethrone the current king at a ${priceFormatter((king?.token.marketcap ?? 0), 2)} mcap</Typography>
                                            </Box>
                                    }
                                    <Box py={2} mt="1em" sx={{ '*': { fontSize: { sm: '14px', xs: '12px' }, textAlign: 'justify' } }}>
                                        <Typography color="#E7E3D8" sx={{ fontSize: { sm: '14px', xs: '12px' }, marginBottom: '1em' }}>
                                            When the market cap reaches ${priceFormatter(TARGET_MARKET_CAP, 2, true, true)}, all the liquidity from the bonding curve will be deposited into {dexList?.join(', ') ?? 'DEX'}, and burned. Progression increases as the price goes up.
                                        </Typography>
                                        <Typography color="#E7E3D8" sx={{ fontSize: { sm: '14px', xs: '12px' } }}>
                                            There are {priceFormatter(ethers.formatEther(poolInfo?.virtualTokenReserve ?? 0n), 2, true, true)} tokens still available for sale in the bonding curve and there is {priceFormatter(ethers.formatEther(poolInfo?.ethReserve ?? 0n), 2, true, true)} PLS in the bonding curve.
                                        </Typography>
                                    </Box>
                                </>
                                : <Typography color="#E7E3D8" sx={{ fontSize: { sm: '14px', xs: '12px' } }} mt="1em">
                                    Launched {new Date(detailData?.launchedAt).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' })} on <a href="/" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>https//coin.quest</a>
                                </Typography>
                        }

                        <HolderBox component="ol" mb="1.5em">
                            <Box component="h3">Holder distribution</Box>
                            <Box component="li">
                                <Typography>1.</Typography>
                                <Box display="flex" alignItems="center">
                                    <User address={PULSECHAIN_CONTRACT_ADDRESS} color="#9E9E9E" user={{
                                        username: "Remaining supply", avatar: "bondingCurv"
                                    }} />
                                </Box>
                                <Link to={`${PULSECHAIN_SCAN_URL}/address/${PULSECHAIN_CONTRACT_ADDRESS}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <LinkIcon sx={{ color: "white", height: 16 }} />
                                </Link>
                                <Typography>{priceFormatter(lpBalance / TOTAL_SUPPLY * 100, 2)} %</Typography>
                            </Box>
                            {holders.length > 0 && holders.filter((item: any) => item.tokenAmount * 100 / TOTAL_SUPPLY >= 0.01).sort((a: any, b: any) => b.tokenAmount - a.tokenAmount).map((item: any, index: number) => (
                                <Box component="li" key={index}>
                                    <Typography>{index + 2}.</Typography>
                                    <Box display="flex" alignItems="center">
                                        <User user={item.user} address={item.holderAddress} postfix={item.holderAddress === item.creatorAddress ? " (Creator) " : ""} color="#9E9E9E" />
                                    </Box>
                                    <Link to={`${PULSECHAIN_SCAN_URL}/address/${item.holderAddress}`} target="_blank" style={{ textDecoration: 'none' }}>
                                        <LinkIcon sx={{ color: "white", height: 16 }} />
                                    </Link>
                                    <Typography>{priceFormatter(item.tokenAmount / TOTAL_SUPPLY * 100, 2)} %</Typography>
                                </Box>
                            ))}
                        </HolderBox>
                    </Box>

                    <HistoryBox display={{ xs: 'block', sm: 'none' }} />
                </Box>
            </div>
            <Dialog open={modal === "reply"} fullWidth keepMounted onClose={isLoading ? undefined : () => showModal(undefined)}>
                <DialogTitle>
                    {replyId ? `Reply to #${String(replyId).padStart(6, '0')}` : "Add reply"}
                    <IconButton onClick={() => showModal(undefined)}>
                        <CloseIcon sx={{ color: "#9E9E9E" }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl variant="standard">
                        <InputLabel shrink>Comment</InputLabel>
                        <BootstrapInput multiline fullWidth rows={6} value={comment} onChange={(e) => setComment(e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={() => showModal(undefined)} color="secondary" fullWidth>Close</Button>
                    <Button onClick={handlePost} fullWidth disabled={isLoading}>
                        Post
                        {isLoading && <CircularProgress size={18} style={{ color: "white", marginLeft: "1em" }} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </PageBox>
    );
};