import { createChart, ColorType, Time } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';

export const priceWithoutZero = (price: number | string) => {
    return String(price).replace(/\.(.*[^0])0+$/, ".$1").replace(/\.0+$/, "")
}

export const priceFormatter = (value: number | string, decimals = 18, willRemoveTail = true, hasUnits = false) => {
    const formatter = new Intl.NumberFormat('en-US')
    if(!value || value == 0)
        return '0'
    let unit = ''
    function addUnit(v: number) {
        if(!hasUnits)
            return v
        if(v > 1e9) {
            unit = 'B'
            return v / 1e9
        }
        if(v > 1e6) {
            unit = 'M'
            return v / 1e6
        }
        if(v > 1e3) {
            unit = 'K'
            return v / 1e3
        }
        return v
    }
    function removeTail(v: string) {
        return willRemoveTail ? priceWithoutZero(v) : v
    }
    const price = addUnit(Number(value))
    let fractions = 18
    if(Math.abs(price) > 1)
        fractions = 2
    if(Math.abs(price) > 0.0001)
        fractions = 6
    fractions = unit ? 2 : Math.min(decimals, fractions)
    const [upper, under] = price.toFixed(fractions).split('.')
    if(Number(upper) > 0 || fractions <= 6)
        return `${removeTail(`${formatter.format(Number(upper))}.${under ?? 0}`)}${unit}`
    const count = under.match(/0+/)?.[0].length ?? 0
    const subscripts = '₀₁₂₃₄₅₆₇₈₉'
    return removeTail(`${formatter.format(Number(upper))}.0${String(count).split('').map(n => subscripts[Number(n)]).join('')}${under.slice(count, count + 4)}`)
}

export const TVChartSimple = (props: any) => {
    const {
        data,
        current,
        colors: {
            backgroundColor = 'transparent',
            textColor = '#babec5',
            gridColor = '#242733'
        } = {},
    } = props;

    const timezoneOffset = new Date().getTimezoneOffset() * 60

    const [series, setSeries] = useState<any>()
    const chartContainerRef = useRef<any>();

    useEffect(() => {
        const handleResize = () => {
            if(chartContainerRef?.current)
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,                    
            },
            grid: {
                vertLines: { color: gridColor },
                horzLines: { color: gridColor },
            },
            width: chartContainerRef.current.clientWidth,
            height: 300,
        });
        chart.applyOptions({
            localization: {
                priceFormatter,
            },
        });
        chart.timeScale().applyOptions({
            ticksVisible: true,
            timeVisible: true,
        });
        chart.timeScale().fitContent();
        if(props.onHover)
            chart.subscribeCrosshairMove(params => props.onHover(params.seriesData.values().next()?.value))
        // chart.timeScale().scrollToPosition(5);

        const series = chart.addCandlestickSeries({
            upColor: '#26a69a',
            downColor: '#ef5350',
            borderVisible: false,
            wickUpColor: '#26a69a',
            wickDownColor: '#ef5350',
        });
        setSeries(series)

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            chart.remove();
        };
    }, [backgroundColor, gridColor, textColor])

    useEffect(() => {
        if(series && data) {
            series.setData(data.map((c: any) => ({ ...c, time: c.time - timezoneOffset })))
        }
    }, [data, series])

    useEffect(() => {
        if(series && current) {
            try {
                series.update({ ...current, time: current.time - timezoneOffset })
            } catch(ex) {}
        }
    }, [current, series])

    return (
        <div ref={chartContainerRef}/>
    );
};
