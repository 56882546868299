import { Box, styled } from "@mui/material";

const Toggle = styled(Box)`
    background: #110B28;
    border-radius: 18px;
    padding: 8px;
    display: flex;
    gap: 8px;
    font-family: 'Inter';
    width: fit-content;
    white-space: nowrap;
    z-index: 1;
    & > div {
        padding: 12px 24px;
        border-radius: 10px;
        color: #B0B5B3;
        text-align: center;
        flex: 1;
        &.active {
            background: white;
            color: #433DFF;
        }
        cursor: pointer;
    }
`
export default Toggle