import { useGlobalState } from "@reactivers/hooks";


export function useUserInfo() {
    const { globalState, setGlobalState } = useGlobalState()
    const setUserInfo = (userInfo: any) => {
        setGlobalState((state: any) => ({
            ...state,
            userInfo: userInfo ? {
                ...(state.userInfo ?? {}),
                ...userInfo
            } : undefined
        }))
    }
    return {
        userInfo: globalState.userInfo, setUserInfo
    }
}