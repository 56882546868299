// src/pages/Dashboard/DashboardStyles.ts
import { Box, TextField, styled } from "@mui/material";
import SwapBanner1 from '../../assets/images/dashboard-1.png';
import SwapBanner2 from '../../assets/images/dashboard-2.png';
import SwapBanner3 from '../../assets/images/dashboard-3.png';
import Overlay1 from '../../assets/images/grad-1.png';
import Overlay2 from '../../assets/images/grad-2.png';

export const BannerBox = styled(Box)`
  padding: 0;
  margin-bottom: 3em;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.14) 100%);
  // border-radius: 48px;
  position: relative;

  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;

    &::before {
      position: absolute;
      content: '';
      background: url(${Overlay1}) no-repeat left top;
      background-size: contain;
      top: -15%;
      left: 0;
      width: 60vw;
      height: 60vw;
    }

    &::after {
      position: absolute;
      content: '';
      background: url(${Overlay2}) no-repeat right top;
      background-size: contain;
      top: -10%;
      right: 0;
      width: 60vw;
      height: 60vw;
    }
  }

  // &:before, &:after {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 1px;
  //   left: 0;
  //   background: linear-gradient(90deg, #FFF0 10%, #FFF3, #FFF0 90%);
  // }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  // box-shadow: 0px 22px 42px 0px #00000042;
  overflow: hidden;

  .banner-bg {
    border-radius: 24px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 228px;
    display: flex;
    background-color: #1c1631;

    &::before, &::after {
      content: '';
      background-size: cover;
      flex: 1;
    }

    &::before {
      background: url(${SwapBanner2}) no-repeat left top;
      opacity: 0.1;
    }

    &::after {
      background: url(${SwapBanner3}) no-repeat right top;
      opacity: 0.08;
    }

    background-image: 
      linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
      linear-gradient(321.94deg, rgba(255, 255, 255, 0) 39.39%, rgba(255, 255, 255, 0.08) 98.18%);
  }

  .banner-actor {
    position: absolute;
    left: 16px;
    top: 0;
    right: 16px;
    height: 228px;
    background: url(${SwapBanner1}) no-repeat center center;
    background-size: contain;
    mix-blend-mode: color-dodge;
    border-radius: 24px;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    // padding: 20px;
    border-radius: 30px;

    // .banner-bg, .banner-actor {
    //   left: 5%;
    //   top: 20px;
    //   right: 5%;
    // }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 15px 0;
    border-radius: 20px;

    // .banner-bg, .banner-actor {
    //   left: 15px;
    //   top: 15px;
    //   right: 15px;
    // }

    .banner-bg::after {
      display: none;
    }

    .banner-actor {
      background-position: center;
    }
  }
`;

export const Banner = styled(Box)`
  cursor: pointer;
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 228px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    height: 632px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: auto;
  }

  label > p {
    font-weight: 700;
    text-shadow: 0px 4px 4px #0008;
  }

  label {
    text-align: right;
  }

  .token-logo {
    height: 180px;
    min-width: 120px;
    margin-top: 5em;
    transform: rotate(-3deg);
    border: 10px solid white;
    border-radius: 16px;
    background: #ddd;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      height: 100px;
      min-width: 100px;
      margin-top: 0;
      border-width: 5px;
    }
  }

  // &:after {
  //   content: '';
  //   ${({ theme }) => theme.breakpoints.down("md")} {
  //     display: none;
  //   }
  //   position: absolute;
  //   width: 100%;
  //   height: 1px;
  //   top: 318px;
  //   left: 0;
  //   background: linear-gradient(90deg, #FFF0 10%, #FFF3, #FFF0 90%);
  // }
`;

export const SearchToken = styled(TextField)`
  align-self: stretch;
  & .MuiOutlinedInput-root {
    background: #110B28;
    border-radius: 16px;
    font-size: 1em;

    & fieldset {
      border-color: transparent !important;
    }
  }
`;

export const LetsGo = styled(Box)`
  position: relative;
  overflow: hidden;
  text-decoration: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 200%;
    bottom: -33px;
    border: 2px solid #fce69c;
    height: 36px;
    border-radius: 100%;
    transform: rotate(5deg);
  }
  p {
    z-index: 1;
    position: relative;
    background-image: linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const OrderGroup = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 10px 20px;
  border-radius: 16px;
  background: #110B28;
  z-index: 1;

  & label, & .MuiSelect-select {
    font-size: 12px;
  }

  & label {
    line-height: 12px;
  }

  & .MuiInput-root:before, & .MuiInput-root:after {
    display: none;
  }

  & .MuiInput-input {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiInput-input:focus {
    background: none;
  }
`;

export const GradientBorder = styled(Box)`
  background: linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%);
  padding: 1px;
`

export const OrderBoxRoot = styled(Box)`
  & > label {
    padding: 16px;
    height: 100%;
    background: #110B28;
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      background: #110B28DD;
    }
  }
  position: relative;
  & .overlay {
    position: fixed;
    top:0; left:0; right:0; bottom:0;
    z-index: 3;
  }
  & .menu {
    position: absolute;
    top:calc(100% + 6px); right:0;
    border-radius: 10px;
    min-width: calc(100% - 2px);
    box-sizing: border-box;
    z-index: 3;
  }
  & ul {
    background: #110B28;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 9px;
    color: #B0B5B3;
    font-family: Inter;
    font-size: 14px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0.8em 1em;
      &:hover {
        color: #E12D85;
      }
      &.active {
        color: #E12D85;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    align-self: stretch;
    & > label {
      padding: 12px 16px;
    }
  }
`

export const CardGrid = styled(Box)`
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  ${({ theme }) => theme.breakpoints.up(1400)} {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  ${({ theme }) => theme.breakpoints.down(800)} {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
