import { Avatar, Box, Button, Grid, LinearProgressProps, Menu, MenuItem, Typography, styled } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import repliesIcon from '../../assets/images/replies.png';
import marketcapIcon from '../../assets/images/marketcap.png';
import TokenLogo from "../tokenLogo";
import { priceFormatter } from "../tvchart/chart";
import { Link, useNavigate } from "react-router-dom";
import { Creator } from "./user";
import { StyledLinearProgress } from "../../pages/DetailsPage/DetailsPageStyles";
import { SERVER_URL, TARGET_MARKET_CAP } from "../../abis/constants";
import React, { useCallback, useMemo } from "react";
import { useUserInfo } from "../../hooks/user";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import axios from "axios";
import { toast } from "react-toastify";

const StyledCard = styled(Box)`
  position: relative;
  border-radius: 16px;
  background: linear-gradient(#110B28, #110B28) padding-box,
    linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
  border: 1px solid transparent;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 16px;
  }

  &:hover {
    box-shadow: 0px 0px 10px 5px #FDFFB370;
    & > a {
      background: #292341;
    }
  }
  z-index: 1;
`;

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <StyledLinearProgress variant="determinate" {...props} />
      <Typography variant="body2" color="white" fontSize="12px" sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, textAlign: 'center', lineHeight: '16px' }}>{`${Math.round(props.value)}%`}</Typography>
    </Box>
  );
}

function TokenCard({ token, ...props }: any) {
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const { walletProvider } = useWeb3ModalProvider()

  const isAdmin = useMemo(() => !!userInfo?.admin?.id, [userInfo])
  
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRemove = useCallback(async (e: any) => {
    e.stopPropagation()
    setContextMenu(null);
    try {
      const provider = new BrowserProvider(walletProvider as Eip1193Provider)
      const signer = await provider.getSigner()
      const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
      const signature = await signer.signMessage(msg)

      axios.post(`${SERVER_URL}/tokens/move/${token.tokenAddress}`, {
        signature, msg, category: 'NSFW'
      }).then(({data}) => {
        if (data.success) {
          toast.success("Token removed successfully")
        } else {
          toast.error(data.message)
        }
      }).catch(err => {})
    } catch(ex) {}
  }, [token, walletProvider])

  return (
    <StyledCard {...props} onClick={() => navigate(`/${token.network}/${token.tokenAddress}`)} onContextMenu={isAdmin ? handleContextMenu : undefined}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" gap={2}>
            {token.tokenName.length > 20 ? <Typography color="#E12D85" fontSize="20px" fontFamily="Inter" fontWeight="bold">{token.tokenName.substring(0, 10)}...</Typography> : <Typography color="#E12D85" fontSize="20px" fontFamily="Inter" fontWeight="bold">{token.tokenName}</Typography>}
            <Box display="flex" alignItems="center" mt="-0.5rem" flexWrap="wrap">
              <Typography color="#9E9E9E" fontSize={14}>Ticker:</Typography>
              <Typography color="#D9D9D9" fontSize={14} ml={0.5}>{token.tokenSymbol}</Typography>
              {
                !!token.launchedAt &&
                <Typography fontSize={12} color="white" ml={2} sx={{ borderRadius: 16, bgcolor: "#66bb6a" }} px={2}>Launched</Typography>
              }
            </Box>
            <Box display="flex" gap="0.2rem" alignItems="center">
              <Creator token={token} />
              <Typography color="#9E9E9E" fontSize={14}>—</Typography>
              <Typography color="#9E9E9E" fontSize={14}>creator</Typography>
            </Box>
            <Box display="flex" gap="0.2rem" alignItems="center">
              <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={repliesIcon} />
              <Typography color="#D9D9D9" fontSize={14}>{token.replies}</Typography>
              <Typography color="#9E9E9E" fontSize={14}>replies</Typography>
            </Box>
            <Box display="flex" gap="0.2rem" alignItems="center">
              <Avatar sx={{ width: 24, height: 24, mr: '0.5rem' }} src={marketcapIcon} />
              <Typography color="#D9D9D9" fontSize={14}>${priceFormatter(token.marketcap, 2)}</Typography>
              <Typography color="#9E9E9E" fontSize={14}>—</Typography>
              <Typography color="#9E9E9E" fontSize={14}>Market cap</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
          <Box sx={{ width: { xs: '100px', md: '170px' }, height: { xs: '100px', md: '170px' } }}>
            <TokenLogo logo={token.tokenImage} style={{ width: '100%', height: '100%' }} />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        {
          !token.launchedAt &&
          <LinearProgressWithLabel variant="determinate" value={token.marketcap * 100 / TARGET_MARKET_CAP} style={{ borderRadius: '16px', height: '16px' }} />
        }
      </Box>
      <Typography sx={{ color: '#9E9E9E', my: '1rem' }} fontSize={12}>
        {token.tokenDescription && token.tokenDescription.length > 40 ? `${token.tokenDescription.substring(0, 40)}...` : token.tokenDescription}        
      </Typography>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" mt="auto">
        <Link to={`/${token.network}/${token.tokenAddress}`} style={{ textDecoration: 'none' }}>
          <Box display="flex" alignItems="center" mt="auto">
            <Typography color="white" fontSize={14}>See more</Typography>
            <KeyboardArrowRightIcon sx={{ color: "white" }} />
          </Box>
        </Link>
        {
          !!props.onUpdate && !token.launchedAt &&
          <Button onClick={(e: any) => {
            e.stopPropagation()
            props.onUpdate(token.tokenAddress)
          }}>Update</Button>
        }
      </Box>
      <Menu
        open={contextMenu !== null && isAdmin}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        onClick={(e: any) => e.stopPropagation()}
      >
        <MenuItem onClick={handleRemove}>Move {token.tokenName} to [NSFW]</MenuItem>
      </Menu>
    </StyledCard>
  );
}

export default TokenCard;
