import { Box, Button, Container, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider, ethers, formatEther, parseEther } from 'ethers';
import React, { useEffect } from "react";
import fairLaunchFactoryABI from "../abis/FairLaunchFactoryABI.json";
import { PULSECHAIN_CONTRACT_ADDRESS } from "../abis/constants";

function AdminPage() {
    const ownerAddress = "0x0be53d9FA63FD5817acd216c5B77514417D138FA"; //owner wallet
    const ownerAddress2 = "0xf121149c25C100E838d5DFCc574aDfb91BE6aEB4"
    const [contractAddress, setContractAddress] = React.useState('');
    const [contractBalance, setContractBalance] = React.useState('0');
    const [withdrawAmount, setWithdrawAmount] = React.useState('0');
    const [platformBalance, setPlatformBalance] = React.useState('0');
    const [targetMarketcap, setTargetMarketcap] = React.useState('0');
    const [targetLPAmount, setTargetLPAmount] = React.useState('0');
    const [totalSupply, setTotalSupply] = React.useState('0');

    const [tokenOwnerFeePercent, setTokenOwnerFeePercent] = React.useState('0');
    const [maxBuyPercent, setMaxBuyPercent] = React.useState('0');
    const [maxSellPercent, setMaxSellPercent] = React.useState('0');
    const [stableToken, setStableToken] = React.useState('0');
    const [refundETH, setRefundETH] = React.useState(false);
    const [useAmountsIn, setUseAmountsIn] = React.useState(false);
    const [createTokenFeeAmount, setCreateTokenFeeAmount] = React.useState('0');
    const [burnLiquidity, setBurnLiquidity] = React.useState(false);
    const [platformBuyFeePercent, setPlatformBuyFeePercent] = React.useState('0');
    const [platformSellFeePercent, setPlatformSellFeePercent] = React.useState('0');



    /*
        uint256 public tokenOwnerLPFee;
    uint256 public firstBuyFeeUSD;
    mapping(address => uint256) private tokenTrades;
    uint256 public MAX_SELL_PERCENT; // max percent of sell amount (default 3%)
    uint256 public PLATFORM_BUY_FEE_PERCENT;
    uint256 public PLATFORM_SELL_FEE_PERCENT;
    */

    const [tokenOwnerLPFee, setTokenOwnerLPFee] = React.useState('');



    const { address, chainId } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    //const [signer, setSigner] = React.useState(null as any);
    const [fairLaunchContract, setFairLaunchContract] = React.useState(null as any);

    const funcWithdraw = async () => {
        await fairLaunchContract.withdrawETH(ethers.parseEther(withdrawAmount)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const withdrawPlatformBalance = async () => {
        await fairLaunchContract.withdrawPlatformBalance(ethers.parseEther(platformBalance)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcTotalSupplyUpdate = async () => {
        console.log({
            totalSupply
        })
        await fairLaunchContract.setTotalSupply(Number(totalSupply)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcTargetLPUpdate = async () => {
        console.log({
            targetLPAmount
        })
        await fairLaunchContract.setTargetLPAmount(Number(targetLPAmount)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcTargetMarketcapUpdate = async () => {
        console.log({
            targetMarketcap
        })
        await fairLaunchContract.setTargetMarketCap(Number(targetMarketcap)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateTokenOwnerFeePercent = async () => {
        console.log({
            tokenOwnerFeePercent
        })
        await fairLaunchContract.setTokenOwnerFeePercent(Number(tokenOwnerFeePercent)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateMaxBuyPercent = async () => {
        console.log({
            maxBuyPercent: Number(maxBuyPercent) * 100
        })
        await fairLaunchContract.setMaxBuyPercent(Number(maxBuyPercent) * 100).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }
    const funcUpdateMaxSellPercent = async () => {
        console.log({
            maxSellPercent: Number(maxSellPercent) * 100
        })
        await fairLaunchContract.setMaxSellPercent(Number(maxSellPercent) * 100).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }
    const funcUpdatePlatformBuyPercent = async () => {
        console.log({
            platformBuyPercent: Number(platformBuyFeePercent)
        })
        await fairLaunchContract.setPlatformBuyFeePercent(Number(platformBuyFeePercent)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }
    const funcUpdatePlatformSellPercent = async () => {
        console.log({
            platformSellPercent: Number(platformSellFeePercent)
        })
        await fairLaunchContract.setPlatformSellFeePercent(Number(platformSellFeePercent)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateStableToken = async () => {
        await fairLaunchContract.setStableToken(stableToken).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateRefundETH = async () => {
        await fairLaunchContract.enableRefundETH(refundETH).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateUseAmountsIn = async () => {
        await fairLaunchContract.enableUseAmountsIn(useAmountsIn).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateCreateTokenFeeAmount = async () => {
        await fairLaunchContract.setCreateTokenFeeAmount(parseEther(createTokenFeeAmount)).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateBurnLiquidity = async () => {
        await fairLaunchContract.setBurnLiquidity(burnLiquidity).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateTokenOwnerLPFee = async () => {
        await fairLaunchContract.setTokenOwnerLPFee(tokenOwnerLPFee).then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdatePause = async () => {
        await fairLaunchContract.pause().then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const funcUpdateUnpause = async () => {
        await fairLaunchContract.unpause().then((res: any) => {
            console.log(res);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const init = async (tempChainId: any) => {
        if (!walletProvider) {
            console.log("Wallet provider is not available.");
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const tempSigner = await ethersProvider.getSigner()
        if (!tempSigner)
            throw Error("Connect wallet");
        // setSigner(tempSigner);
        const tempFairLaunchContract = new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, tempSigner);
        setContractAddress(PULSECHAIN_CONTRACT_ADDRESS);
        setFairLaunchContract(tempFairLaunchContract);
        const nativeBalance = await tempSigner.provider.getBalance(PULSECHAIN_CONTRACT_ADDRESS); // contract balance
        const tempNativeBalanceString = ethers.formatEther(nativeBalance);
        setContractBalance(tempNativeBalanceString);

        setTargetMarketcap((await tempFairLaunchContract.TARGET_MARKET_CAP()).toString());
        setTargetLPAmount((await tempFairLaunchContract.TARGET_LP_AMOUNT()).toString());
        setTotalSupply(formatEther((await tempFairLaunchContract.TOTAL_SUPPLY()).toString()));
        setTokenOwnerFeePercent((await tempFairLaunchContract.TOKEN_OWNER_FEE_PERCENT()).toString());
        setMaxBuyPercent((Number((await tempFairLaunchContract.MAX_BUY_PERCENT()).toString()) / 100).toString());
        setMaxSellPercent((Number((await tempFairLaunchContract.MAX_SELL_PERCENT()).toString()) / 100).toString());
        setStableToken((await tempFairLaunchContract.STABLE_TOKEN()).toString());
        setRefundETH((await tempFairLaunchContract.refundETH()).toString());
        setUseAmountsIn((await tempFairLaunchContract.useAmountsIn()).toString());
        setCreateTokenFeeAmount(formatEther((await tempFairLaunchContract.CREATE_TOKEN_FEE_AMOUNT()).toString()));
        setBurnLiquidity((await tempFairLaunchContract.burnLiquidity()).toString());
        setPlatformBalance((await tempFairLaunchContract.platformBalance()).toString());
        setTokenOwnerLPFee(formatEther((await tempFairLaunchContract.tokenOwnerLPFee())).toString());
        setPlatformBuyFeePercent((await tempFairLaunchContract.PLATFORM_BUY_FEE_PERCENT()).toString());
        setPlatformSellFeePercent((await tempFairLaunchContract.PLATFORM_SELL_FEE_PERCENT()).toString());
    }

    useEffect(() => {
        init(369);
    }, []);

    useEffect(() => {
        init(369);
    }, [address]);

    return (
        <Box>
            {(address?.toLowerCase() === ownerAddress?.toLowerCase() || address?.toLowerCase() === ownerAddress2?.toLowerCase()) &&
                <Container>
                    {/* <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} sx={{ mx: 'auto' }}>
                            <FormControl fullWidth sx={{ mb: '1rem' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={network}
                                    onChange={handleChange}
                                >

                                    <MenuItem value='Ethereum' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Ethereum</Typography>
                                            <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Avax' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Avax</Typography>
                                            <Avatar src={AvaxIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Arbitrum' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Arbitrum</Typography>
                                            <Avatar src={ArbitrumIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value='Base' >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography sx={{ fontSize: 18 }}>Base</Typography>
                                            <Avatar src={BaseIcon} sx={{ width: 25, height: 25 }} alt="avatar" />
                                        </Box>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                    </Grid2> */}
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} inputProps={{ readOnly: true }} label="Contract Address" value={contractAddress} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={6} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} inputProps={{ readOnly: true }} label="Contract Balance" value={contractBalance} variant="outlined" fullWidth />
                        </Grid2>
                        {/* <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Withdraw Address" value={withdrawAddress} onChange={(e) => setWithdrawAddress(e.target.value)} variant="outlined" fullWidth />
                        </Grid2> */}
                        {/* <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Withdraw amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={12} sm={2}>
                            <Button onClick={funcWithdraw} variant="contained" sx={{ height: 55 }} fullWidth>Withdraw</Button>
                        </Grid2> */}
                        <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Withdraw amount" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'> <Button onClick={funcWithdraw} variant="contained" sx={{ height: 55 }} fullWidth>Withdraw</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={5} mb='1rem'>
                            <TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Platform Balance" value={platformBalance} onChange={(e) => setWithdrawAmount(e.target.value)} variant="outlined" fullWidth />
                        </Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'> <Button onClick={withdrawPlatformBalance} variant="contained" sx={{ height: 55 }} fullWidth>Withdraw</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Target Marketcap" value={targetMarketcap} onChange={(e) => setTargetMarketcap(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTargetMarketcapUpdate} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Target LP Amount" value={targetLPAmount} onChange={(e) => setTargetLPAmount(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTargetLPUpdate} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Total supply" value={totalSupply} onChange={(e) => setTotalSupply(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcTotalSupplyUpdate} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Token owner fee percent" value={tokenOwnerFeePercent} onChange={(e) => setTokenOwnerFeePercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateTokenOwnerFeePercent} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Max Buy Percent" value={maxBuyPercent} onChange={(e) => setMaxBuyPercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateMaxBuyPercent} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Max Sell Percent" value={maxSellPercent} onChange={(e) => setMaxBuyPercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateMaxSellPercent} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Platform Buy Fee Percent" value={platformBuyFeePercent} onChange={(e) => setPlatformBuyFeePercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdatePlatformBuyPercent} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Platform Sell Fee Percent" value={platformSellFeePercent} onChange={(e) => setPlatformSellFeePercent(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdatePlatformSellPercent} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Stable Token" value={stableToken} onChange={(e) => setStableToken(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateStableToken} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Refund ETH" value={refundETH} onChange={(e) => setRefundETH(Boolean(e.target.value))} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateRefundETH} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Use Amounts In" value={useAmountsIn} onChange={(e) => setUseAmountsIn(Boolean(e.target.value))} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateUseAmountsIn} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Create Token Fee Amount" value={createTokenFeeAmount} onChange={(e) => setCreateTokenFeeAmount(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateCreateTokenFeeAmount} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Burn Liquidity" value={burnLiquidity} onChange={(e) => setBurnLiquidity(Boolean(e.target.value))} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateBurnLiquidity} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={10} sm={10} mb='1rem'><TextField id="outlined-basic" sx={{ mb: '1rem' }} label="Token owner LP fee amount" value={tokenOwnerLPFee} onChange={(e) => setTokenOwnerLPFee(e.target.value)} variant="outlined" fullWidth /></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateTokenOwnerLPFee} variant="contained" sx={{ height: 55 }} fullWidth>Update</Button></Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdatePause} variant="contained" sx={{ height: 55 }} fullWidth>Pause</Button></Grid2>
                        <Grid2 xs={2} sm={2} mb='1rem'><Button onClick={funcUpdateUnpause} variant="contained" sx={{ height: 55 }} fullWidth>Unpause</Button></Grid2>
                    </Grid2>
                </Container>
            }
        </Box>
    )
}

export default AdminPage;