import { Avatar, Slide, Box, Button, FormControl, styled, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, InputBase, CircularProgress, InputLabel, Alert, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DollarIcon from '@mui/icons-material/MonetizationOn';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TransitionProps } from '@mui/material/transitions';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Confetti from 'react-confetti-boom';
import { NumericFormat } from "react-number-format";
import { useWeb3ModalAccount, useWeb3ModalProvider, useSwitchNetwork } from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider, N, ethers } from 'ethers';
import { socket } from "../utils/socket";
import dex9inchIcon from '../assets/images/dex-9inch.png';
import dex9mmIcon from '../assets/images/dex-9mm.png';
import dexPulsexIcon from '../assets/images/dex-pulsex.png';
import PageBox from "../components/layout/pageBox";
import { COINHUB_TELEGRAM_URL, COINHUB_TWITTER_URL, PULSECHAIN_CONTRACT_ADDRESS, PULSECHAIN_RPC_URL, SERVER_URL } from "../abis/constants";
import fairLaunchFactoryABI from "../abis/FairLaunchFactoryABI.json";
import { priceFormatter, priceWithoutZero } from "../components/tvchart/chart";
//import { uploadImageToIPFS } from "../utils";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: '16px',
        position: 'relative',
        backgroundColor: '#29243d',
        border: 'none',
        padding: '10px 20px',
    },
}))

const CurrencyInput = styled(Box)`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: #FFFFFF0F;
    padding: 10px 20px;
    gap: 8px;
    & span.balance {
        align-self: flex-end;
        color: #FFF8;
        font-size: small;
    }
    & input {
        text-align: right;
        font-size: 32px;
        background: transparent;
        color: white;
        border: none;
        outline: none;
    }
    & button.MuiButton-root {
        padding: 0 2px;
        font-size: x-small;
        border: none;
        border-radius: 6px;
    }
`

const AvatarWrapper = styled(Box)`
    position: relative;
    display: inline-block;
    & > label {
        position: absolute;
        right: 0;
        bottom: 0;
    }
`

const HiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clipPath: inset(50%);
    height: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 100%;
`

const Banner = styled('img')`
    border-radius: 48px 48px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    mask-image: linear-gradient(180deg, #000 30%, #0000 100%);
    object-fit: cover;
`

const DexSelect = styled(IconButton) <{ label?: string, checked?: boolean }>`
    border-radius: 16px;
    background: #29243d;
    padding: 12px 1px 1px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    img {
        height: 40px;
    }
    &::after {
        flex: 0 0 100%;
        font-size: 14px;
        background: ${({ checked }) => checked ? "#E12D85" : "#FFF2"};
        border-top: 1px dashed #FFF2;
        padding: 8px 0;
        content: '${({ label }) => label}';
    }
`

const SuccessScreen = styled(Box)`
    canvas {
        position: fixed !important;
    }
`

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateCurvePage() {
    const [network, setNetwork] = React.useState('Pulsechain');
    const [deployModal, setDeployModal] = React.useState(false);
    const [tokenAddressDeployed, setTokenAddressDeployed] = React.useState<string>();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [coinName, setCoinName] = React.useState("");
    const [coinTicker, setCoinTicker] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [telegramLink, setTelegramLink] = React.useState('');
    const [twitterLink, setTwitterLink] = React.useState('');
    const [webLink, setWebLink] = React.useState('');
    const [fairLaunchContract, setFairLaunchContract] = React.useState(null as any);
    const [nativeBalanceString, setNativeBalanceString] = React.useState("");
    // const [initLiquidityAmount, setInitLiquidityAmount] = React.useState("")
    const [initBuyAmount, setInitBuyAmount] = React.useState('0');
    const [maxLiquidity, setMaxLiquidity] = React.useState(0);
    const [maxBuyAmount, setMaxBuyAmount] = React.useState(0);
    const [platformFee, setPlatformFee] = React.useState(1000n);
    const [avatar, setAvatar] = React.useState<any>()
    const [banner, setBanner] = React.useState<any>()
    const [more, setMore] = React.useState(false)
    // const [showParticles, setShowParticles] = React.useState(false)
    const [bitmapRouters, setBitmapRouters] = React.useState(1)

    const fileBannerRef = React.useRef<any>()
    const fileLogoRef = React.useRef<any>()

    const provider = new ethers.JsonRpcProvider(PULSECHAIN_RPC_URL)

    const error = React.useMemo(() => {
        if (!coinName)
            return "You have to type coin name"
        if (!coinTicker)
            return "You have to type coin ticker"
        if (Number(initBuyAmount) > maxBuyAmount)
            return `The initial purchase cannot exceed ${priceFormatter(maxBuyAmount)} PLS`
        return undefined
    }, [coinName, coinTicker, maxBuyAmount])

    useEffect(() => {
        const init = async () => {
            if (!isConnected) return;
            const tempFairLaunchContract = new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, provider);
            setFairLaunchContract(tempFairLaunchContract);
            const nativeBalance = await provider.getBalance(address!);
            const tempNativeBalanceString = ethers.formatEther(nativeBalance);
            setNativeBalanceString(tempNativeBalanceString);
            const platformFee = await tempFairLaunchContract.CREATE_TOKEN_FEE_AMOUNT();
            setPlatformFee(platformFee)
            const maxLiquidity = await tempFairLaunchContract.initialTokenLPAmount();
            setMaxLiquidity(Number(ethers.formatEther(maxLiquidity)))
            const maxBuyPercent = await tempFairLaunchContract.MAX_BUY_PERCENT();
            const maxBuyAmount = BigInt(0) * BigInt(maxBuyPercent) / 10000n;
            setMaxBuyAmount(Number(ethers.formatEther(maxBuyAmount)))
        }
        init();
    }, [chainId, address])

    // const setInitLiquidityPercent = (percent: number) => {
    //     const amount = maxLiquidity * (percent * 100) / 100
    //     setInitLiquidityAmount(priceWithoutZero(amount))
    // }

    const setInitBuyPercent = (percent: number) => {
        const amount = Number(ethers.formatEther(ethers.parseEther(nativeBalanceString) * BigInt(percent * 100) / 100n))
        setInitBuyAmount(priceWithoutZero(amount))
    }

    const handleClickOpen = () => {
        if (!isConnected) {
            toast.error("Please connect wallet");
            return;
        }

        // if (initLiquidityAmount === "") {
        //     toast.error("Initial Liquidity should be greater than 1M");
        //     return;
        // }

        // if (initLiquidityAmount === "" || Number(initLiquidityAmount) < 1000000) {
        //     toast.error("Initial Liquidity should be greater than 1M");
        //     return;
        // }

        if (coinName === "") {
            toast.error("Please enter coin name");
            return;
        }

        if (coinTicker === "") {
            toast.error("Please enter coin ticker");
            return;
        }

        if (!avatar) {
            toast.error("Please upload coin logo");
            return;
        }

        if (bitmapRouters === 0) {
            toast.error("Please select at least one DEX to launch");
            return;
        }

        setDeployModal(true);
    };

    const uploadLogo = async (img: any) => {

        let formData = new FormData(); // instantiate it
        // suppose you have your file ready
        formData.set('image', img);
        // add some data you collected from the input fields
        const r = axios.post(`${SERVER_URL}/tokens/upload`, formData, {
            headers: {
                "api-key": "hola",
                'content-type': 'multipart/form-data' // do not forget this 
            }
        })

        return r;

    }

    const tokenDeploy = async () => {
        setIsLoading(true);



        try {
            if (!isConnected) {
                toast.error("Please connect wallet");
                return;
            }


            const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
            const signer = await ethersProvider.getSigner()
            const signerBalance = await ethersProvider.getBalance(signer.address)


            const tempFairLaunchContract = new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, signer);
            // const totalAmount = ethers.parseEther(initBuyAmount) + platformFee + ethers.parseEther(maxLiquidity)
            const createFeeAmount = await fairLaunchContract.CREATE_TOKEN_FEE_AMOUNT();
            const firstBuyFee = Number(initBuyAmount) > 0 ? await tempFairLaunchContract.getFirstBuyFee(ethers.ZeroAddress) : 0n
            //const initialLiquidityAmount = await fairLaunchContract.INITIAL_AMOUNT();
            // const stableUnit = await fairLaunchContract.STABLE_UNIT()
            const totalAmount = ethers.parseEther(initBuyAmount) + createFeeAmount + firstBuyFee
            // check if the user has enough balance to deploy the token

            if (Number(ethers.formatEther(signerBalance)) < Number(ethers.formatEther(totalAmount))) {
                toast.error("Insufficient balance");
                setIsLoading(false);
                return;
            }


            const ethBalance = await ethersProvider.getBalance(signer.address);
            if (Number(ethers.formatEther(ethBalance)) < Number(ethers.formatEther(totalAmount))) {
                toast.error("Insufficient balance");
                setIsLoading(false);
                return;
            }

            // from initial initBuyAmount (ex. 0.001 ETH), estimate tokenInitialAmount
            // const contractTotalSupply = 10 ** 9;
            // const initAmountFloat = parseFloat(initBuyAmount);
            // if (initAmountFloat > 1) {
            //     alert("Initial buy amount must be less than 1");
            //     return;
            // }
            //const { IpfsHash: logoLink } = await uploadImageToIPFS(fileLogoRef.current.files[0]);
            //const { IpfsHash: bannerLink } = fileBannerRef.current && fileBannerRef.current.files && fileBannerRef.current.files.length > 0 ? await uploadImageToIPFS(fileBannerRef.current.files[0]) : { IpfsHash: undefined };

            const msg = Buffer.from(ethers.randomBytes(24)).toString('hex')
            const signature = await signer.signMessage(msg)

            const logoUploadResult = await uploadLogo(fileLogoRef.current.files[0])
            const logoLink = logoUploadResult.data.file.filename

            let bannerLink;
            if (fileBannerRef.current && fileBannerRef.current.files && fileBannerRef.current.files.length > 0) {
                const bannerLogoResult = await uploadLogo(fileBannerRef.current.files[0])
                bannerLink = bannerLogoResult.data.file.filename
            }

            console.log({ logoLink, bannerLink })

            const metadata = {
                msg,
                signature,
                tokenDescription: description,
                tokenName: coinName,
                tokenSymbol: coinTicker,
                tokenImage: logoLink,
                tokenBanner: bannerLink,
                creatorAddress: signer.address,
                network: network,
                telegramLink: telegramLink,
                twitterLink: twitterLink,
                webLink: webLink
            }

            const { data: { success, sig } } = await axios.post(`${SERVER_URL}/tokens`, metadata)
            if (success) {
                const tx = await tempFairLaunchContract.createToken(
                    coinName, coinTicker, ethers.parseEther(initBuyAmount), bitmapRouters, sig, { value: totalAmount }
                ); //set token initial amount

                const receipt = await tx.wait();

                toast.success("Token deployed successfully")

                const logCreated = receipt?.logs?.find((x: any) => x.eventName === "TokenCreated");
                const tokenAddress = logCreated.args.token;

                const ethPriceBigInt = BigInt(await fairLaunchContract.getETHPriceByUSD());
                const tokenPriceBigInt = BigInt(await fairLaunchContract.getPrice(tokenAddress));
                const tokenMarketCap = BigInt(await fairLaunchContract.getTokenMarketCap(tokenAddress));
                const ethPriceUSD = ethers.formatEther(ethPriceBigInt)
                const tokenPriceUSD = ethers.formatEther(tokenPriceBigInt * ethPriceBigInt / (10n ** 18n))
                const body = {
                    tokenDescription: description,
                    tokenName: coinName,
                    tokenSymbol: coinTicker,
                    tokenAddress: tokenAddress,
                    tokenImage: logoLink,
                    tokenBanner: bannerLink,
                    marketcap: tokenMarketCap.toString(),
                    price: tokenPriceUSD.toString(),
                    virtualLP: ethPriceUSD.toString(),
                    creatorAddress: signer.address,
                    network: network,
                    replies: 0,
                    telegramLink: telegramLink,
                    twitterLink: twitterLink,
                    webLink: webLink,
                    sig
                }

                const { data: { success } } = await axios.post(`${SERVER_URL}/tokens`, body)

                if (success) {
                    // setShowParticles(true)
                    setDeployModal(false);
                    setTokenAddressDeployed(tokenAddress)
                }
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            console.log({
                error
            });
            // const errorMessage = error?.shortMessage || error?.message || "Error in deploying token";
            // toast.error(errorMessage);
        };
    }

    function capitalize(s: string) {
        return s[0].toUpperCase() + s.slice(1);
    }

    const handleCloseCreatedDialog = () => {
        // reset state
        setCoinName("");
        setCoinTicker("");
        setDescription("");
        setTelegramLink('');
        setTwitterLink('');
        setWebLink('');
        // setInitLiquidityAmount('0');
        setInitBuyAmount('0');
        setAvatar(undefined);
        if (fileLogoRef.current?.files.length)
            fileLogoRef.current.value = ""
        if (fileBannerRef.current?.files.length)
            fileBannerRef.current.value = ""
        setTokenAddressDeployed(undefined)
    }

    const handleClose = () => {
        if (!isLoading)
            setDeployModal(false)
    }

    const handleAvatar = (e: any) => {
        const file = e.target.files[0]
        if (!file)
            return
        const reader = new FileReader()
        reader.onloadend = () => {
            setAvatar(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const handleBanner = (e: any) => {
        console.log('banner')
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setBanner(reader.result)
        }
        reader.readAsDataURL(file)
    }

    return (
        <PageBox>
            {
                !!banner &&
                <Banner src={banner} className="token-banner" />
            }
            <Box display='flex' alignItems='flex-start' justifyContent='center' sx={{ position: 'relative', zIndex: 1 }}>
                <Typography sx={{ fontSize: { sm: 36, xs: 20 } }} color="white" fontFamily="Riffic Free">Create a new coin</Typography>
                <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', position: 'absolute', right: 0 }}>
                    <ArrowLeftIcon sx={{ color: 'white', height: 24 }} />
                    <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                </Link>
            </Box>
            <Box py={3} mx="auto" width={{ md: "40%", sm: "80%", xs: "100%" }} display="flex" flexDirection="column" gap="1.5rem" sx={{ boxSizing: "border-box", zIndex: 1 }}>
                <Box display="flex" gap="1.5rem">
                    <div>
                        <InputLabel shrink>
                            Coin logo
                        </InputLabel>
                        <AvatarWrapper>
                            <Avatar sx={{ width: 135, height: 135, borderRadius: '16px' }} src={avatar}>
                                <DollarIcon sx={{ color: 'white', width: 48, height: 48 }} />
                            </Avatar>
                            <IconButton sx={{ bgcolor: '#E12D8588', '&:hover': { bgcolor: '#E12D85' } }} component="label">
                                <EditIcon sx={{ width: 16, height: 16 }} />
                                <HiddenInput ref={fileLogoRef} type="file" onChange={handleAvatar} />
                            </IconButton>
                        </AvatarWrapper>
                    </div>
                    <Box width="fit-content" display="flex" flex="1" flexDirection="column" gap="1.5rem">
                        <FormControl variant="standard">
                            <InputLabel shrink>
                                Coin name
                            </InputLabel>
                            <BootstrapInput fullWidth placeholder="Coin name" value={coinName} onChange={(e) => setCoinName(e.target.value)} />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink>
                                Coin ticker
                            </InputLabel>
                            <BootstrapInput fullWidth placeholder="Coin ticker" value={coinTicker} onChange={(e) => setCoinTicker(e.target.value)} />
                        </FormControl>
                    </Box>
                </Box>

                {/* <FormControl variant="standard">
                    <InputLabel shrink>
                        Initial Liquidity
                    </InputLabel>
                    <Box mt={3}>
                        {
                            Number(initLiquidityAmount) < 5000000 &&
                            <Typography color="yellow" fontSize={12} textAlign="right">
                                ⚠️ Deploying with less than 5M PLS may result in significant volatility and increased risk.
                            </Typography>
                        }
                        <CurrencyInput>
                            <Typography component="span" className="balance">
                                Balance: {priceFormatter(nativeBalanceString)}
                            </Typography>
                           
                            <NumericFormat
                                placeholder="0.0"
                                thousandSeparator
                                valueIsNumericString
                                value={initLiquidityAmount}
                                onValueChange={(values) => setInitLiquidityAmount(values.value)}
                            />
                            <Box display="flex" gap="8px">
                                <Button variant="outlined" color="secondary" onClick={() => setInitLiquidityAmount('1000000')}>1M PLS</Button>
                                <Button variant="outlined" color="secondary" onClick={() => setInitLiquidityAmount('10000000')}>10M PLS</Button>
                                <Button variant="outlined" color="secondary" onClick={() => setInitLiquidityAmount('100000000')}>100M PLS</Button>
                            </Box>
                        </CurrencyInput>
                    </Box>
                </FormControl> */}

                <FormControl variant="standard">
                    <InputLabel shrink>
                        Description
                    </InputLabel>
                    <BootstrapInput fullWidth placeholder="Description" multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                </FormControl>
                <FormControl variant="standard">
                    <InputLabel shrink>
                        DEX to launch
                    </InputLabel>
                    <Box display="flex" alignItems="center" justifyContent="space-between" gap="1em" mt={3}>
                        <DexSelect checked={!!(bitmapRouters & 0x1)} label="9INCH" onClick={() => setBitmapRouters(bitmapRouters ^ 0x1)} sx={{ flex: 1 }}>
                            <img src={dex9inchIcon} />
                        </DexSelect>
                        <DexSelect checked={!!(bitmapRouters & 0x4)} label="PulseX" onClick={() => setBitmapRouters(bitmapRouters ^ 0x4)} sx={{ flex: 1 }}>
                            <img src={dexPulsexIcon} />
                        </DexSelect>
                        {/* <DexSelect checked={!!(bitmapRouters & 0x2)} label="9mm" onClick={() => setBitmapRouters(bitmapRouters ^ 0x2)} sx={{ flex: 1 }}>
                            <img src={dex9mmIcon} />
                        </DexSelect> */}
                    </Box>
                </FormControl>
                <Box display="flex" alignItems="center" alignSelf="flex-end" sx={{ cursor: "pointer" }}>
                    {
                        more
                            ? <ArrowDownIcon sx={{ color: 'white', height: 24 }} />
                            : <ArrowRightIcon sx={{ color: 'white', height: 24 }} />
                    }
                    <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small" onClick={() => setMore(!more)}>More options</Typography>
                </Box>
                {
                    more &&
                    <>
                        <FormControl variant="standard">
                            <InputLabel shrink>
                                Telegram Link
                            </InputLabel>
                            <BootstrapInput fullWidth placeholder="Telegram Link" value={telegramLink} onChange={(e) => setTelegramLink(e.target.value)} />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink>
                                Twitter Link
                            </InputLabel>
                            <BootstrapInput fullWidth placeholder="Twitter Link" value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} />
                        </FormControl>
                        <FormControl variant="standard">
                            <InputLabel shrink>
                                Website Link
                            </InputLabel>
                            <BootstrapInput fullWidth placeholder="Website Link" value={webLink} onChange={(e) => setWebLink(e.target.value)} />
                        </FormControl>


                        <IconButton sx={{ borderRadius: '16px', textTransform: 'none', background: '#FFFFFF0F', p: '12px 20px', fontSize: 16 }} component="label">
                            Select banner image
                            <HiddenInput ref={fileBannerRef} type="file" onChange={handleBanner} />
                        </IconButton>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">DEX</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={numberOfRouters}
                                label="Number of routers"

                            >
                                <MenuItem value={"1"}><img style={{ marginBottom: "25px" }} src={NineInchIcon} width={10} height={10} alt="telegramColorIcon" /></MenuItem>
                                <MenuItem
                                    value={""}><img style={{ marginBottom: "25px" }} src={NineMMIcon} width={10} height={10} alt="pulseXicom" />
                                </MenuItem>
                                <MenuItem
                                    value={"3"}><img style={{ marginBottom: "25px" }} src={PulsexIcon} width={10} height={10} alt="pulseXicom" />
                                </MenuItem>

                            </Select>
                        </FormControl> */}
                    </>
                }
                <Button sx={{ background: '#9E1F63', color: 'white', padding: '12px', borderRadius: '16px' }} fullWidth onClick={handleClickOpen}>Deploy</Button>
            </Box>
            <Dialog
                disableEscapeKeyDown={isLoading}
                open={deployModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create & Buy?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" mb='1rem'>
                        Choose how many PLS you want to buy (optional).
                        Buying a small amount of coins helps encourage sales and improves visability of your token.
                    </DialogContentText>
                    {/* <FormControl variant="standard" sx={{ opacity: 0.2 }}>
                        <InputLabel shrink>
                            Spend PLS for initial liquidity
                        </InputLabel>
                        <CurrencyInput mt={3}>
                            <Typography component="span" className="balance">
                                Max: {maxLiquidity}
                            </Typography>
                            <NumericFormat
                                placeholder="0.0"
                                thousandSeparator
                                valueIsNumericString
                                value={initLiquidity}
                                onValueChange={(values) => setInitLiquidity(values.value)}
                                disabled
                            />
                            <Box display="flex" gap="8px">
                                <Button variant="outlined" color="secondary" disabled onClick={() => setInitLiquidityPercent(0.25)}>25%</Button>
                                <Button variant="outlined" color="secondary" disabled onClick={() => setInitLiquidityPercent(0.5)}>50%</Button>
                                <Button variant="outlined" color="secondary" disabled onClick={() => setInitLiquidityPercent(0.75)}>75%</Button>
                                <Button variant="outlined" color="secondary" disabled onClick={() => setInitLiquidityPercent(1)}>MAX</Button>
                            </Box>
                        </CurrencyInput>
                    </FormControl> */}
                    <FormControl variant="standard">
                        <InputLabel shrink>
                            Spend PLS to buy
                        </InputLabel>
                        <CurrencyInput mt={3}>
                            <Typography component="span" className="balance">
                                Balance: {priceFormatter(nativeBalanceString)}
                            </Typography>
                            <NumericFormat
                                placeholder="0.0"
                                thousandSeparator
                                valueIsNumericString
                                value={initBuyAmount}
                                onValueChange={(values) => {
                                    if (values.value === "")
                                        setInitBuyAmount('0')
                                    else
                                        setInitBuyAmount(values.value)
                                }}
                            />
                            <Box display="flex" gap="8px">
                                <Button variant="outlined" color="secondary" onClick={() => setInitBuyPercent(0.25)}>25%</Button>
                                <Button variant="outlined" color="secondary" onClick={() => setInitBuyPercent(0.5)}>50%</Button>
                                <Button variant="outlined" color="secondary" onClick={() => setInitBuyPercent(0.75)}>75%</Button>
                                <Button variant="outlined" color="secondary" onClick={() => setInitBuyPercent(1)}>MAX</Button>
                            </Box>
                        </CurrencyInput>
                    </FormControl>
                    {
                        platformFee > 0n &&
                        <Alert severity="warning">
                            Deploy Fee ({priceFormatter(Number(ethers.formatEther(platformFee)))} PLS)
                        </Alert>
                    }
                    {
                        !!error &&
                        <Alert severity="error">
                            {error}
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} color="secondary" onClick={handleClose} fullWidth>Cancel</Button>
                    <Button disabled={isLoading || !!error} endIcon={isLoading ? <CircularProgress color="inherit" size={14} /> : undefined} onClick={tokenDeploy} fullWidth>
                        {isLoading ? 'Deploying' : 'Deploy'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={!!tokenAddressDeployed}
                TransitionComponent={Transition}
                onClose={handleCloseCreatedDialog}
                aria-describedby="dialogTokenCreated"
            >
                <DialogTitle>{"Your token has been deployed!"}</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                        <Link to={COINHUB_TELEGRAM_URL} target="_blank" style={{ color: 'white', textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: "column" }}>
                            <Avatar sx={{ width: 135, height: 135, borderRadius: '16px' }} src={avatar}>
                                <DollarIcon sx={{ color: 'white', width: 48, height: 48 }} />
                            </Avatar>
                            <Box whiteSpace="nowrap" pt={3}>
                                {'Join CoinQuest on '}
                                <Typography to={COINHUB_TELEGRAM_URL} target="_blank" component={Link} color="#E12D85">Telegram </Typography>
                                and
                                <Typography to={COINHUB_TWITTER_URL} target="_blank" component={Link} color="#E12D85"> Twitter</Typography>
                            </Box>
                        </Link>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Link to={`/${network}/${tokenAddressDeployed}`} style={{ color: '#E12D85', textDecoration: 'none', display: 'flex', alignItems: 'center', margin: 'auto' }}>
                        <ArrowLeftIcon />
                        Go to token page
                    </Link>
                </DialogActions>
            </Dialog>
            {
                !!tokenAddressDeployed &&
                <SuccessScreen>
                    <Confetti shapeSize={20} mode="fall" particleCount={100} colors={['#ff577f', '#ff884b', '#2C945D', '#205998']} />
                </SuccessScreen>
            }
        </PageBox >
    )
}