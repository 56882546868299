import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout';
import DashboardPage from './pages/Dashboard/Dashboard';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { MainContextProvider } from './context/mainContext';
import { createWeb3Modal, defaultConfig, useWeb3Modal } from '@web3modal/ethers/react'
import { GlobalStateProvider } from "@reactivers/use-global-state";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import walletConnectLogo from "./assets/images/wallet-connect.jpg";
// import coinbaseLogo from "./assets/images/coin/Coinbase.svg";
// import ethereumLogo from "./assets/images/coin/eth-1.png";
import logo from "../src/assets/images/logo.png";
import CreateCurvePage from './pages/createCurvs';
import MyCurvsPage from './pages/myCurvs';
import DetailPage from './pages/DetailsPage/DetailsPage';
import ProfilePage from './pages/profile';
import React from 'react';
// import { socket } from './utils/socket';
import AdminPage from './pages/admin';
import { PULSECHAIN_CHAIN_ID, PULSECHAIN_RPC_URL } from './abis/constants';
import LeaderboardPage from './pages/leaderboard';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: "Inter",
  }
});

const projectId = '07efe273ec05006951c30c4399c2e9b9';

const pulsechain = {
  chainId: PULSECHAIN_CHAIN_ID,
  name: 'Pulsechain',
  currency: 'PLS',
  explorerUrl: 'https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#',
  rpcUrl: PULSECHAIN_RPC_URL,
}


// test sds

const metadata = {
  name: 'Coin Quest',
  description: 'CoinQuest prevents rugs by making sure that all created tokens are safe. Each coin on CoinQuest is a fair-launch with no presale and no team allocation.',
  url: '...', // origin must match your domain & subdomain
  icons: [logo]
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: false, // true by default
  enableCoinbase: false, // true by default
  rpcUrl: PULSECHAIN_RPC_URL, // used for the Coinbase SDK
  defaultChainId: PULSECHAIN_CHAIN_ID, // used for the Coinbase SDK
});

createWeb3Modal({
  ethersConfig,
  chains: [pulsechain],
  // chainImages: {
  //   1: ethereumLogo
  // },
  // connectorImages: {
  //   coinbaseWallet: coinbaseLogo,
  //   walletConnect: walletConnectLogo,
  // },
  projectId,
  // allWallets: 'HIDE'
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {


  return (
    <div className="App">
      <MainContextProvider>
        <ToastContainer />
        <ThemeProvider theme={darkTheme}>
          {/* @ts-ignore */}
          <GlobalStateProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<MainLayout />}>
                  <Route path='/' element={<DashboardPage />} />
                  <Route path='/ref/:code' element={<DashboardPage />} />
                  <Route path='/leaderboard' element={<LeaderboardPage />} />
                  <Route path='/create' element={<CreateCurvePage />} />
                  <Route path='/my_tokens' element={<MyCurvsPage />} />
                  <Route path='/:chain/:id' element={<DetailPage />} />
                  <Route path='/profile/:address' element={<ProfilePage />} />
                  <Route path='/profile/:address/:tab' element={<ProfilePage />} />
                  <Route path='/admin' element={<AdminPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </GlobalStateProvider>
        </ThemeProvider>
      </MainContextProvider>
    </div>
  );
}

export default App;