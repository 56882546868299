import { useState } from "react";
import { Outlet } from "react-router-dom";
// import Toolbar from '@mui/material/Toolbar';
import { Box, CssBaseline, styled } from "@mui/material";
import Header from "./header";
import Background from "../bgoverlay";
import Footer from "./footer";
import { Toaster } from "react-hot-toast";
// import Sidebar from "./sidebar";


const drawerWidth = 240;

const Main = styled(Box)`
    // position: relative;
    margin-top: 5rem;
    padding: 1rem 14px;
    ${props => props.theme.breakpoints.down("md")} {
        // padding: 6rem 0 2rem;
    }
    ${props => props.theme.breakpoints.down("sm")} {
        margin-top: 100px;
    }
`

function MainLayout() {
    const [ isMenuOpen, setMenuOpen ] = useState(false)
    return (
        <>
            {/* <Background /> */}
            <Header drawerWidth={drawerWidth} onMenuOpen={(isOpen) => setMenuOpen(isOpen)}/>
            {/* <CssBaseline /> */}
            {/* <Sidebar /> */}
            <Main
                component="main"
                // sx={{ flexGrow: 1, p: { sm: 3, xs: 0 }, pt: { md: '1em', sm: '5em', xs: '8em' }, pb: { xs: 3, sm: 3 }, marginTop: '4rem', position: 'relative' }}
                display={ isMenuOpen ? 'none' : 'block' }
            >
                <Outlet />
            </Main>
            {/* <Footer/> */}
            <Toaster />
        </>
    );
}

export default MainLayout;
