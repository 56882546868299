import { Link } from "react-router-dom";
import { Box, Badge, LinearProgress, styled } from "@mui/material";
import Logo from '../../assets/images/logo.png';

export const IntervalButton = styled('button')`
  background: transparent;
  color: #76808f;
  border-radius: 4px;
  padding: 2px 6px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #24273380;
  }
  &.active {
    color: orange;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  background: #00000033;
  & > .MuiLinearProgress-bar {
    background: #E12D85;
  }
`;

export const TVChartContainer = styled(Box)`
  border-radius: 16px;
  border: 8px solid #2a2e39;
  overflow: hidden;
  background: #131722;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${Logo}) no-repeat center;
    opacity: 0.3;
    filter: grayscale(0.7);
  }
`;

export const TradeBox = styled(Box)`
  background: #00000030;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 1fr 1fr;
  align-items: center;
  padding: 0.3em 1em;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
  }
`;

export const SwapBox = styled(Box)`
  background: linear-gradient(#1c1631, #1c1631) padding-box,
    linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
  border: 1px solid transparent;
  backdrop-filter: blur(8px);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center items horizontally */
  justify-content: center;  /* Center items vertically */
  gap: 15px;
  padding: 24px;
  width: 100%;
  max-width: 370px;  /* Ensure a maximum width to prevent overflow */
  margin: 0 auto;  /* Center the SwapBox */
  box-sizing: border-box;

  & button.medium, & button.small {
    background: #FFFFFF0F;
    color: #E7E3D8;
    border-radius: 24px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    text-transform: none;
    align-items: center;
    min-width: unset;
    &.medium:hover {
      background: #FFFFFF2F;
      color: white;
    }
  }

  & button.small {
    padding: 4px 8px;
  }

  &.disabled {
    display: flex;  /* Ensure flex display for centering */
    flex-direction: column;
    align-items: center;  /* Center items horizontally */
    justify-content: center;  /* Center items vertically */
    position: relative;
    overflow: hidden;
    filter: grayscale(0.8);
    opacity: 0.5;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`;


export const CurrencyInput = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #00000042;
  border-radius: 16px;
  padding: 10px 20px;
  gap: 8px;
  & span.balance {
    align-self: flex-end;
    color: #FFF8;
    font-size: small;
  }
  & input {
    font-size: 20px;
    background: transparent;
    color: white;
    border: none;
    outline: none;
    width: 100%;
  }
`;

export const SocialLink = styled(Link)`
  opacity: 0.6;

`;

export const StyledBadge = styled(Badge)`
  width: fit-content;
  vertical-align: middle;
  & .MuiBadge-badge {
    ${(props) => props.invisible ? "display: none;" : ""}
    transform: translate(100%, 0);
    top: 0;
    right: 0;
    color: white;
    padding: 0 12px;
  }
`;

export const HolderBox = styled(Box)`
  list-style-position: inside;
  padding: 0;
  color: white;
  h3 {
    font-family: Riffic Free;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted #ddd6;
    padding: 8px 0;
    & > :first-of-type {
      flex: 0 0 1.5em;
    }
    & > :last-child {
      flex: 1;
      text-align: right;
    }
  }
`;

export const Banner = styled('img')`
  border-radius: 48px 48px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  mask-image: linear-gradient(180deg, #000A 30%, #0000 100%);
  object-fit: cover;
  ${({ theme }) => theme.breakpoints.down("md")} {
    border-radius: 24px 24px 0 0;
  }
`;

export const MetamaskButton = styled('img')`
cursor: pointer;
`